import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import React from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image';

export const PressResourcePressKit = ({ slice }) => {
  return (
    <div className="press-resource__press-kit">
      {slice.primary.imagePress ? (
        <div className="press-resource__press-kit__image">
          <img
            {...generateImageProps(slice.primary.imagePress, {
              width: 800,
              sizes: '300px',
            })}
          />
        </div>
      ) : null}

      {slice.primary.titlePress ? (
        <h2 className="press-resource__press-kit__title">
          {slice.primary.linkPress ? (
            <Link field={slice.primary.linkPress}>
              {prismicH.asText(slice.primary.titlePress)}
            </Link>
          ) : (
            prismicH.asText(slice.primary.titlePress)
          )}
        </h2>
      ) : null}

      {slice.primary.descriptionPress ? (
        <div className="press-resource__press-kit__description">
          <PrismicRichText field={slice.primary.descriptionPress} />
        </div>
      ) : null}

      {slice.primary.linkTextPress ? (
        <div className="press-resource__press-kit__button">
          <Button as="button" theme="light" rightIcon="right-arrow">
            {slice.primary.linkTextPress ? (
              <PrismicRichText field={slice.primary.linkTextPress} />
            ) : null}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
