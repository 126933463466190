import { PrismicRichText } from '@prismicio/react';
import React from 'react';

import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image';

const ThreeVideos = ({ slice }) => (
  <div className="three-videos">
    <div className="three-videos__gradient" />
    <MaxWidth>
      <div className="three-videos__video-container">
        {slice.items.map((item, index) => {
          return (
            <div className="three-videos__item" key={index}>
              <div className="three-videos__img-gradient" />

              {item.image ? (
                <div className="three-videos__item__image">
                  <img
                    {...generateImageProps(item.image, {
                      width: 800,
                      sizes: '400px',
                    })}
                  />
                </div>
              ) : null}

              <div className="three-videos__item__content">
                <Link field={item.link}>
                  <div className="three-videos__item__content__container">
                    {item.title ? (
                      <div className="three-videos__item__content__container__title">
                        <PrismicRichText field={item.title} />
                      </div>
                    ) : null}

                    {item.description ? (
                      <div className="three-videos__item__content__container__description">
                        <PrismicRichText field={item.description} />
                      </div>
                    ) : null}
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </MaxWidth>
  </div>
);

export default ThreeVideos;
