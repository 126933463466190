import React from "react";
import { Button } from "../../components/Button";
import { PrismicRichText } from "@prismicio/react";
import { MaxWidth } from "../../components/MaxWidth";
import { TicketIcon, InfoIcon } from "../../components/Icons";

const Tarifs = ({ slice }) => (
  <div className="pricing">
    <div className="pricing__background">
      <picture>
        <source
          type="image/avif"
          srcSet="/backgrounds/pricing-background.avif"
        />
        <img
          src="/backgrounds/pricing-background.png"
          alt="pricing-background"
          draggable={false}
        />
      </picture>
    </div>

    <MaxWidth>
      <div className="pricing__container">
        <div className="pricing__prices">
          {slice.primary.mainTitle ? (
            <PrismicRichText field={slice.primary.mainTitle} />
          ) : null}

          <div className="pricing__prices__container">
            <div className="pricing__prices__icon">
              <TicketIcon />
            </div>

            <div className="pricing__prices__text">
              {slice.items.map((price, index) => (
                <div className="pricing__prices__item" key={index}>
                  <div className="pricing__prices__item__content">
                    {price.itemTitle ? (
                      <PrismicRichText field={price.itemTitle} />
                    ) : null}

                    {price.itemDescription ? (
                      <div className="pricing__prices__item__description">
                        <PrismicRichText field={price.itemDescription} />
                      </div>
                    ) : null}
                  </div>

                  {price.itemPrice ? (
                    <div className="pricing__prices__item__price">
                      <PrismicRichText field={price.itemPrice} />
                    </div>
                  ) : null}
                </div>
              ))}

              {slice.primary.linkTextTicketing ? (
                <div className="pricing__prices__link">
                  <Button
                    field={slice.primary.linkTicketing}
                    theme="blue"
                    leftIcon="ticket"
                    rightIcon="up-right-arrow"
                  >
                    {slice.primary.linkTicketing ? (
                      <PrismicRichText
                        field={slice.primary.linkTextTicketing}
                      />
                    ) : null}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="pricing__secondary">
          {slice.primary.secondaryTitle ? (
            <PrismicRichText field={slice.primary.secondaryTitle} />
          ) : null}

          <div className="pricing__secondary__container">
            <div className="pricing__secondary__icon">
              <InfoIcon />
            </div>

            <div className="pricing__secondary__text">
              {slice.primary.secondaryContent ? (
                <div className="pricing__secondary__text__content">
                  <PrismicRichText field={slice.primary.secondaryContent} />
                </div>
              ) : null}

              {slice.primary.linkText ? (
                <div className="pricing__secondary__text__link">
                  <Button field={slice.primary.link} theme="blue">
                    {slice.primary.linkText ? (
                      <PrismicRichText field={slice.primary.linkText} />
                    ) : null}
                  </Button>
                </div>
              ) : null}

              {slice.primary.subText ? (
                <div className="pricing__secondary__text__sub-text">
                  <PrismicRichText field={slice.primary.subText} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </MaxWidth>
  </div>
);

export default Tarifs;
