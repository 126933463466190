import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';

import { PressResourceList } from './list.js';
import { PressResourcePressKit } from './press-kit.js';

const PressResource = ({ slice }) => {
  return (
    <div className="press-resource">
      <MaxWidth>
        <div className="press-resource__container">
          <PressResourceList slice={slice} />

          <PressResourcePressKit slice={slice} />
        </div>
      </MaxWidth>
    </div>
  );
};

export default PressResource;
