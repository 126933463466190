import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import classnames from 'classnames';

import { MaxWidth } from '../../components/MaxWidth';
import { RichText } from 'prismic-reactjs';

export const Title = ({
  items = { supTitle: null, title: null, description: null },
  params = {
    isFirst: false,
    variation: 'default',
  },
}) => {
  return (
    <div
      className={classnames('title', {
        'title--first': params.isFirst,
        'title--white': params.variation === 'titleWhite',
        'title--transparent-black':
          params.variation === 'titleTransparentBlack',
      })}
    >
      <div className="title__container">
        {items.supTitle ? (
          <div className="title__sup-title">
            <PrismicRichText field={items.supTitle} />
          </div>
        ) : null}

        {items.title ? (
          <div className="title__title">
            {params.isFirst ? (
              <h1>{RichText.asText(items.title)}</h1>
            ) : (
              <h2>{RichText.asText(items.title)}</h2>
            )}
          </div>
        ) : null}

        {items.description ? (
          <div className="title__description">
            <PrismicRichText field={items.description} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
