export const isImage = (image) => {
  return image && image.url;
};

export const changeImageUrl = (url, width) => {
  if (/w=\d+&h=\d+/.test(url)) {
    return url.replace(/w=\d+&h=\d+/, `w=${width}`);
  } else if (/\?auto/.test(url)) {
    return `${url}&w=${width}`;
  }

  return url;
};

/**
 * @param {object} image
 * @param {object} [params={}]
 * @param {number|number[]} [params.width=800]
 * @param {boolean} [params.lazy=false]
 */
export const generateImageProps = (
  image,
  { width = 800, lazy = false, sizes = "100vw" } = {}
) => {
  const url = image.url;

  const srcSet =
    typeof width === "object"
      ? `${changeImageUrl(url, 600)} 600w,${width
          .map((w) => {
            return `${changeImageUrl(url, w)} ${w}w`;
          })
          .join(",")}`
      : `${changeImageUrl(url, 600)} 600w,${changeImageUrl(
          url,
          width
        )} ${width}w`;

  return {
    ...(lazy
      ? {
          loading: "lazy",
        }
      : {}),
    src: changeImageUrl(url, width),
    srcSet,
    sizes,
    alt: image.alt ?? "",
    width: image.dimensions ? image.dimensions.width : undefined,
    height: image.dimensions ? image.dimensions.height : undefined,
  };
};
