import { RichText } from 'prismic-reactjs';
import React from 'react';
import { MaxWidth } from '../../components/MaxWidth';

const ICONS = {
  mail: (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.834 12.8337H2.16732C1.74739 12.8337 1.34466 12.6668 1.04773 12.3699C0.750799 12.073 0.583984 11.6703 0.583984 11.2503V1.68145C0.601744 1.27358 0.776366 0.888326 1.0714 0.60613C1.36643 0.323934 1.75906 0.166606 2.16732 0.166993H14.834C15.2539 0.166993 15.6566 0.333808 15.9536 0.63074C16.2505 0.927673 16.4173 1.3304 16.4173 1.75033V11.2503C16.4173 11.6703 16.2505 12.073 15.9536 12.3699C15.6566 12.6668 15.2539 12.8337 14.834 12.8337ZM2.16732 3.22916V11.2503H14.834V3.22916L8.50065 7.45033L2.16732 3.22916ZM2.80065 1.75033L8.50065 5.55033L14.2007 1.75033H2.80065Z"
        fill="#DFA244"
      />
    </svg>
  ),
  telephone: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22842 9.22867C7.64542 10.8107 5.81142 12.3247 5.08642 11.5997C4.04942 10.5627 3.40942 9.65867 1.12142 11.4977C-1.16558 13.3357 0.591418 14.5617 1.59642 15.5657C2.75642 16.7257 7.08042 15.6277 11.3544 11.3547C15.6274 7.08067 16.7224 2.75667 15.5614 1.59667C14.5564 0.590666 13.3364 -1.16533 11.4984 1.12167C9.65942 3.40867 10.5624 4.04867 11.6014 5.08667C12.3234 5.81167 10.8104 7.64567 9.22842 9.22867Z"
        fill="#DFA244"
      />
    </svg>
  ),
};

const HeadContact = ({ slice }) => {
  return (
    <section className="head-contact">
      <p className="head-contact__sup-title">
        {RichText.asText(slice.primary.supTitle)}
      </p>
      <h2 className="head-contact__title">
        {RichText.asText(slice.primary.title)}
      </h2>
      <div className="head-contact__grid">
        {slice.items.map((item, index) => {
          const href =
            item.icon === 'mail'
              ? `mailto:${RichText.asText(item.text)}`
              : `tel:${RichText.asText(item.text)
                  .replace(/ /g, '')
                  .replace(/\(([^)]+)\)/g, '')}`;

          return (
            <p className="head-contact__grid__block" key={index}>
              {ICONS[item.icon]}
              <a href={href}>{RichText.asText(item.text)}</a>
            </p>
          );
        })}
      </div>
    </section>
  );
};

export default HeadContact;
