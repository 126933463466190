import Downloader from 'js-file-downloader';
import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';

import config from '../../helpers/config.js';
import { generateImageProps } from '../../helpers/image.js';

let increment = 1;

const originalUrl = (url) => {
  return url.replace(/\?auto=compress,format/, '?auto=compress');
};

const getDownloadFileName = (url) => {
  const extension = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1];

  const name = `${config.siteName} ${increment}.${extension}`;

  increment++;

  return name;
};

const Gallery = ({ slice }) => {
  return (
    <div className="gallery">
      <MaxWidth>
        <div className="gallery__container">
          {slice?.items?.map((item, i) => {
            const url = originalUrl(item.image.url);

            const handleClick = (event) => {
              event.preventDefault();

              new Downloader({
                url,
                filename: getDownloadFileName(url),
              });
            };
            return (
              <div className="gallery__item" key={i}>
                <img
                  {...generateImageProps(item.image, {
                    width: 800,
                    sizes: '400px',
                  })}
                />

                <a href={url} target="_blank" onClick={handleClick}>
                  <img
                    src="/icons/download.svg"
                    alt=""
                    width="34"
                    height="34"
                    draggable={false}
                  />
                  Télécharger la photo
                </a>
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Gallery;
