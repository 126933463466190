// This file is generated by Slice Machine. Update with care!

import ArticleContent from './ArticleContent';
import ArticlesSelection from './ArticlesSelection';
import Banner from './Banner';
import CallToAction from './CallToAction';
import CompactConvenient from './CompactConvenient';
import Contact from './Contact';
import EchoPresse from './EchoPresse';
import Faq from './Faq';
import Gallery from './Gallery';
import HeadContact from './HeadContact';
import Hero from './Hero';
import ImageText from './ImageText';
import InfosPratiques from './InfosPratiques';
import Journey from './Journey';
import LastArticles from './LastArticles';
import LastEvents from './LastEvents';
import MapPratique from './MapPratique';
import NotFound from './NotFound';
import PartenairesListe from './PartenairesListe';
import RessourcesPresse from './RessourcesPresse';
import ServicesPratiques from './ServicesPratiques';
import Tarifs from './Tarifs';
import TextPage from './TextPage';
import Texte from './Texte';
import ThreeVideos from './ThreeVideos';
import Title from './Title';
import VideoText from './VideoText';

export {
	ArticleContent,
	ArticlesSelection,
	Banner,
	CallToAction,
	CompactConvenient,
	Contact,
	EchoPresse,
	Faq,
	Gallery,
	HeadContact,
	Hero,
	ImageText,
	InfosPratiques,
	Journey,
	LastArticles,
	LastEvents,
	MapPratique,
	NotFound,
	PartenairesListe,
	RessourcesPresse,
	ServicesPratiques,
	Tarifs,
	TextPage,
	Texte,
	ThreeVideos,
	Title,
	VideoText,
};

export const components = {
	article_content: ArticleContent,
	articles_selection: ArticlesSelection,
	banner: Banner,
	call_to_action: CallToAction,
	compact_convenient: CompactConvenient,
	contact: Contact,
	echo_presse: EchoPresse,
	faq: Faq,
	gallery: Gallery,
	head_contact: HeadContact,
	hero: Hero,
	image_text: ImageText,
	infos_pratiques: InfosPratiques,
	journey: Journey,
	last_articles: LastArticles,
	last_events: LastEvents,
	map_pratique: MapPratique,
	not_found: NotFound,
	partenaires_liste: PartenairesListe,
	ressources_presse: RessourcesPresse,
	services_pratiques: ServicesPratiques,
	tarifs: Tarifs,
	text_page: TextPage,
	texte: Texte,
	three_videos: ThreeVideos,
	title: Title,
	video_text: VideoText,
};
