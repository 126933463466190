import React from 'react';

export const NumbersItem = ({ title = null, description = null }) => {
  return (
    <div className="numbers-item">
      <div className="numbers-item__title">{title}</div>

      <div className="numbers-item__description">{description}</div>
    </div>
  );
};

export const Numbers = ({ children = null }) => {
  return <div className="numbers">{children}</div>;
};
