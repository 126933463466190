import { PrismicRichText } from '@prismicio/react';
import React from 'react';

import { Button } from '../../components/Button';
import { PositionIcon } from '../../components/Icons';

export const ContactDetails = ({ slice }) => {
  const socialLinks = slice.primary.socialProfiles
    ? slice.primary.socialProfiles.split('\n')
    : [];

  const facebook = socialLinks.find((url) => /facebook.com/.test(url));

  const X = socialLinks.find((url) => /X.com/.test(url));

  const instagram = socialLinks.find((url) => /instagram.com/.test(url));

  const tiktok = socialLinks.find((url) => /tiktok.com/.test(url));

  const youtube = socialLinks.find((url) => /youtube.com/.test(url));

  return (
    <div className="contact__details">
      <div className="contact__position">
        <div className="contact__position__icon">
          <PositionIcon />
        </div>

        <div className="contact__position__content">
          {slice.primary.titlePosition ? (
            <div className="contact__position__content__title">
              <PrismicRichText field={slice.primary.titlePosition} />
            </div>
          ) : null}

          {slice.primary.descriptionPosition ? (
            <div className="contact__position__content__description">
              <PrismicRichText field={slice.primary.descriptionPosition} />
            </div>
          ) : null}

          {slice.primary.linkPosition && slice.primary.linkTextPosition ? (
            <div className="contact__position__content__link">
              <Button
                field={slice.primary.linkPosition}
                horizontalPadding="large"
                height="extra-small"
                theme="transparent"
              >
                {slice.primary.linkTextPosition ? (
                  <PrismicRichText field={slice.primary.linkTextPosition} />
                ) : null}
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <ul className="contact__social">
        {facebook && (
          <li>
            <a href={facebook} target="_blank" rel="noopener">
              <img
                src="/icons/social/facebook.svg"
                alt="Facebook"
                draggable={false}
              />
            </a>
          </li>
        )}

        {instagram && (
          <li>
            <a href={instagram} target="_blank" rel="noopener">
              <img
                src="/icons/social/instagram.svg"
                alt="Instagram"
                draggable={false}
              />
            </a>
          </li>
        )}

        {tiktok && (
          <li>
            <a href={tiktok} target="_blank" rel="noopener">
              <img
                src="/icons/social/tiktok.svg"
                alt="TikTok"
                draggable={false}
              />
            </a>
          </li>
        )}

        {youtube && (
          <li>
            <a href={youtube} target="_blank" rel="noopener">
              <img
                src="/icons/social/youtube.svg"
                alt="Youtube"
                draggable={false}
              />
            </a>
          </li>
        )}

        {X && (
          <li>
            <a href={X} target="_blank" rel="noopener">
              <img src="/icons/social/X.svg" alt="X" draggable={false} />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
