import { PrismicLink } from '@prismicio/react';
import NextLink from 'next/link';
import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { isLink } from '../../helpers/is-link.js';
import config from '../../helpers/config.js';
import { useAppState } from '../../helpers/context.js';
import { decorateUrl } from '../../helpers/utm-decoration.js';

export const Link = ({ field = null, href = null, children, ...rest }) => {
  const appState = useAppState();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const openCookieConsent = () => {
    window.CookieConsentApi.showSettings();
  };

  if (field?.link_type === 'Web' && /^https?:\/\/#/i.test(field?.url)) {
    const anchor = field.url.replace(/^https?:\/\//i, '');

    const handleAnchorClick = (event) => {
      const target = document.querySelector(anchor);

      if (target) {
        event.preventDefault();

        const targetScrollOffsetTop =
          parseInt(
            window
              .getComputedStyle(target)
              .getPropertyValue('--js-scroll-offset-top') || '0',
            10
          ) || 0;

        const offset = targetScrollOffsetTop;

        window.scrollTo({
          top:
            document.scrollingElement.scrollTop +
            target.getBoundingClientRect().top -
            offset,
          behavior: 'smooth',
        });
      }
    };

    switch (anchor) {
      case '#cookie-consent-popup':
        return (
          <button onClick={openCookieConsent} {...rest}>
            {children}
          </button>
        );
      default:
        return (
          <a
            href={anchor}
            target={field.target}
            onClick={handleAnchorClick}
            {...rest}
          >
            {children}
          </a>
        );
        break;
    }
  }

  if (typeof href === 'string') {
    return (
      <NextLink href={decorateUrl(href, appState.parameters)} {...rest}>
        {children}
      </NextLink>
    );
  }

  const El = href ? 'a' : isLink(field) ? PrismicLink : 'div';

  const params = {};

  if (field?.link_type === 'Web' && field?.url) {
    params.target = '_blank';

    const host = new URL(field?.url)?.host;

    if (config.refererHostWhitelist.includes(host)) {
      params.rel = 'noopener';
    } else {
      params.rel = 'noopener noreferrer';
    }
  }

  return (
    <El
      field={
        El === PrismicLink
          ? {
              ...field,
              url: field?.url
                ? decorateUrl(field?.url || '', appState.parameters)
                : null,
            }
          : null
      }
      {...params}
      {...rest}
    >
      {children}
    </El>
  );
};
