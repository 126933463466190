import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';

const CallToAction = ({ slice }) => (
  <div className="cta">
    <MaxWidth>
      <div className="cta__container">
        <div className="cta__title">
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </div>
        <div className="cta__description">
          {slice.primary.description ? (
            <PrismicRichText field={slice.primary.description} />
          ) : null}
        </div>
        <div className="cta__button">
          {slice.primary.buttonLink && slice.primary.buttonText ? (
            <Button field={slice.primary.buttonLink}>
              {slice.primary.buttonText ? (
                <PrismicRichText field={slice.primary.buttonText} />
              ) : null}
            </Button>
          ) : null}
        </div>
      </div>
    </MaxWidth>
  </div>
);

export default CallToAction;
