import { PrismicRichText } from '@prismicio/react';
import { RichText } from 'prismic-reactjs';
import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Text } from '../../components/Text';

import { ContactDetails } from './details.js';
import { ContactForm } from './form.js';

const Contact = ({ slice, slices }) => {
  const isFirst = slices.length > 0 && slices[0].id === slice.id;
  return (
    <div id={slice.primary.anchor} className="contact">
      <MaxWidth>
        <div className="contact__container">
          <div className="contact__top">
            <div className="contact__top__sup-title">
              <PrismicRichText field={slice.primary.supTitle} />
            </div>

            <div className="contact__top__title">
              {isFirst ? (
                <h1>{RichText.asText(slice.primary.title)}</h1>
              ) : (
                <h2>{RichText.asText(slice.primary.title)}</h2>
              )}
            </div>

            <div className="contact__top__description">
              <Text>
                <PrismicRichText field={slice.primary.description} />
              </Text>
            </div>
          </div>

          <ContactForm slice={slice} />

          <ContactDetails slice={slice} />
        </div>
      </MaxWidth>
    </div>
  );
};

export default Contact;
