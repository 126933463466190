import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';

const Subject = ({ slice, index }) => {
  return (
    <div className="subject">
      <MaxWidth>
        <Title
          items={{
            supTitle: slice.primary.supTitle,
            title: slice.primary.title,
            description: slice.primary.description,
          }}
          params={{
            isFirst: index === 0,
            variation: slice.variation,
          }}
        />
      </MaxWidth>
    </div>
  );
};

export default Subject;
