import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichText } from 'prismic-reactjs';

import * as prismicH from '@prismicio/helpers';
import { Button } from '../../components/Button';
import classNames from 'classnames';

import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image';
import { Title } from '../../components/Title';

const NotFound = ({ slice, slices }) => {
  const isFirst = slices.length > 0 && slices[0].id === slice.id;
  return (
    <div
      className={classNames('not-found', {
        'not-found--first': isFirst,
      })}
    >
      <MaxWidth>
        <Title
          items={{
            supTitle: slice.primary.supTitle,
            title: slice.primary.title,
            description: null,
          }}
        />
        {prismicH.asText(slice.primary.buttonText) ? (
          <div className="not-found__button">
            <Button
              field={slice.primary.buttonLink}
              theme="simple"
              rightIcon="right-arrow"
            >
              {slice.primary.buttonText ? (
                <PrismicRichText field={slice.primary.buttonText} />
              ) : null}
            </Button>
          </div>
        ) : null}
      </MaxWidth>
      {slice.primary.image.url && (
        <div className="not-found__image">
          <img
            {...generateImageProps(slice.primary.image, {
              width: 2000,
              sizes: '100vw',
            })}
          />
        </div>
      )}
    </div>
  );
};

export default NotFound;
