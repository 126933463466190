import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs';
import { MaxWidth } from '../../components/MaxWidth';
import { useRef } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';

const MapPratique = ({ slice }) => {
  const cards = useRef([]);
  const slides = useRef([]);
  const [active, setActive] = useState(0);

  const [provinceIndex, setProvinceIndex] = useState(-1);
  const [regionIndex, setRegionIndex] = useState(-1);

  const updateCoords = (map) => {
    if (getComputedStyle(map.default.current).maxWidth === 'none') return;
    const width = map.default.current.clientWidth;
    const maxWidth = parseInt(
      getComputedStyle(map.default.current).maxWidth.replace('px', '')
    );
    const coeff = width / maxWidth;

    map.areas.forEach((area, index) => {
      const coords = area.coords.split(',');
      coords.forEach((coord, index) => {
        coord = parseInt(coord);
        coords[index] = Math.round(coord * coeff).toString();
      });
      area.el.current.setAttribute('coords', coords);
    });
  };

  // GET COORDS => https://www.image-map.net/
  const maps = {
    province: {
      default: useRef(null),
      elements: useRef([]),
      setState: setProvinceIndex,
      areas: [
        {
          coords:
            '343,185,278,178,196,190,101,229,38,198,18,200,2,175,1,151,68,130,106,153,155,151,138,76,179,83,177,96,229,113,237,95,268,77',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '343,184,277,178,196,191,99,231,133,313,150,320,226,238,288,200',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '342,184,286,201,227,238,151,320,146,345,150,377,111,539,177,568,231,343,288,239,322,203',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '341,185,288,241,232,344,178,568,223,575,228,561,248,567,268,584,289,603,321,605,349,599,347,584,351,566,313,380,321,250',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '342,184,322,249,314,383,350,556,372,535,401,523,429,541,458,539,464,550,490,559,526,556,540,529,566,517,583,491,545,472,405,388,356,294',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '343,184,357,294,406,388,544,470,541,458,548,446,534,424,554,412,555,392,536,373,543,359,533,334,500,340,500,318,523,290,406,250',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '343,184,409,251,524,290,547,256,566,257,574,249,578,215,588,178,600,152,525,129,454,171,385,190',
          shape: 'poly',
          el: useRef(null),
        },
        {
          coords:
            '343,184,385,189,456,169,524,128,513,117,497,117,490,107,476,109,450,89,453,71,426,77,415,70,412,54,387,33,357,19,356,8,342,-1,323,10,304,20,305,52,288,72,270,79,289,105,312,138,329,163',
          shape: 'poly',
          el: useRef(null),
        },
      ],
    },
    region: {
      default: useRef(null),
      elements: useRef([]),
      setState: setRegionIndex,
      areas: [
        { coords: '167,21,60', shape: 'circle', el: useRef(null) },
        { coords: '115,170,60', shape: 'circle', el: useRef(null) },
        { coords: '170,270,60', shape: 'circle', el: useRef(null) },
        { coords: '200,560,60', shape: 'circle', el: useRef(null) },
        { coords: '460,460,60', shape: 'circle', el: useRef(null) },
        { coords: '465,315,60', shape: 'circle', el: useRef(null) },
        {
          coords: '596,288,562,323,539,299,539,274,573,264',
          shape: 'poly',
          el: useRef(null),
        },
        { coords: '560,140,60', shape: 'circle', el: useRef(null) },
        {
          coords: '596,289,563,324,595,353,630,322',
          shape: 'poly',
          el: useRef(null),
        },
        { coords: '640,450,60', shape: 'circle', el: useRef(null) },
        { coords: '750,630,60', shape: 'circle', el: useRef(null) },
        { coords: '900,360,60', shape: 'circle', el: useRef(null) },
      ],
    },
  };

  const updateMap = (key, index) => {
    maps[key].elements.current.forEach((el) => {
      el.style.opacity = 0;
    });

    if (index === -1) return;

    maps[key].elements.current[index].style.opacity = 1;
  };

  useEffect(() => updateMap('province', provinceIndex), [provinceIndex]);
  useEffect(() => updateMap('region', regionIndex), [regionIndex]);

  useEffect(() => {
    const observers = [];
    Object.values(maps).forEach((map) => {
      const observer = new ResizeObserver(() => updateCoords(map));
      observer.observe(map.default.current);
      observers.push({ observer, element: map.default.current });
    });

    return () => {
      observers.forEach((obj) => obj.observer.unobserve(obj.element));
    };
  }, [maps.province.areas[0].el]);

  const toggleCard = (index) => {
    if (active === index) return;
    const oldCard = {
      el: cards.current[active],
      map: slides.current[active],
    };
    const newCard = {
      el: cards.current[index],
      map: slides.current[index],
    };
    oldCard.el.style.opacity = 0;
    newCard.el.style.opacity = 0;
    oldCard.map.style.animation = `0.5s slider__card-${
      index > active ? 'left' : 'right'
    }-disappear ease-in-out`;

    setTimeout(() => {
      setActive(index);
      setTimeout(() => {
        newCard.el.style.opacity = 1;
        newCard.map.style.animation = `0.5s slider__card-${
          index > active ? 'left' : 'right'
        }-appear ease-in-out`;
      }, 20);
    }, 200);
  };

  return (
    <section className="map-pratique">
      <MaxWidth>
        {slice.primary.gradient && (
          <div className="map-pratique__gradient"></div>
        )}
        <div className="map-pratique__title">
          <h2>{RichText.asText(slice.primary.title)}</h2>
        </div>
        <ul className="map-pratique__list">
          <li
            className={classNames('map-pratique__list__link', {
              'map-pratique__list__link--active': active === 0,
            })}
          >
            <button onClick={() => toggleCard(0)}>
              {RichText.asText(slice.primary.provinceTitle)}
            </button>
          </li>
          <li className="map-pratique__list__separator"></li>
          <li
            className={classNames('map-pratique__list__link', {
              'map-pratique__list__link--active': active === 1,
            })}
          >
            <button onClick={() => toggleCard(1)}>
              {RichText.asText(slice.primary.regionTitle)}
            </button>
          </li>
        </ul>
        <div className="map-pratique__slider">
          {Object.entries(maps).map(([key, value], index) => {
            return (
              <div
                key={index}
                className={classNames('map-pratique__slider__card', {
                  'map-pratique__slider__card--active': active === index,
                })}
                ref={(el) => (cards.current[index] = el)}
              >
                <div className="map-pratique__slider__card__description">
                  <RichText render={slice.primary[`${key}Description`]} />
                </div>
                <div
                  className={`map-pratique__slider__card__map map-pratique__slider__card__${key}`}
                  ref={(el) => (slides.current[index] = el)}
                >
                  <map name={`map-pratique__slider__card__${key}__map`}>
                    {maps[key].areas.map((area, index) => {
                      return (
                        <area
                          key={index}
                          alt="Area Map"
                          coords={area.coords}
                          shape={area.shape}
                          onMouseEnter={() => value.setState(index)}
                          onMouseLeave={() => value.setState(-1)}
                          ref={(el) => (maps[key].areas[index].el.current = el)}
                        />
                      );
                    })}
                  </map>

                  <img
                    className="map-pratique__slider__card__map_default"
                    useMap={`#map-pratique__slider__card__${key}__map`}
                    src={`/illustrations/maps/${key}/${key}_default.svg`}
                    alt="France Map"
                    draggable="false"
                    ref={(el) => (maps[key].default.current = el)}
                  />

                  {Array(maps[key].areas.length)
                    .fill(1)
                    .map((el, index) => {
                      return (
                        <img
                          key={index}
                          className={`map-pratique__slider__card__map_${index}`}
                          src={`/illustrations/maps/${key}/${key}_${index}.svg`}
                          alt="France Map"
                          draggable="false"
                          useMap={`#map-pratique__slider__card__${key}__map`}
                          ref={(el) => (maps[key].elements.current[index] = el)}
                        />
                      );
                    })}

                  <img
                    className="map-pratique__slider__card__map_mobile"
                    src={`/illustrations/maps/${key}/${key}_mobile.svg`}
                    alt="Mobile France Map"
                    draggable="false"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </section>
  );
};

export default MapPratique;
