import React from 'react';

import { Date } from 'prismic-reactjs';

import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';

const TextPage = ({ slice, context }) => {
  const date = Date(slice.primary.date);

  const formattedDate = Intl.DateTimeFormat(context.layout.lang, {
    dateStyle: 'full',
  }).format(date);

  return (
    <div className="text-page">
      <div className="text-page__image">
        <img src={slice.primary.image.url} alt={slice.primary.image.alt} />
      </div>

      <MaxWidth>
        <div className="text-page__content">
          {slice.primary.supTitle ? (
            <div className="text-page__content--sup-title">
              <PrismicRichText field={slice.primary.supTitle} />
            </div>
          ) : null}

          {slice.primary.title ? (
            <div className="text-page__content--title">
              <PrismicRichText field={slice.primary.title} />
            </div>
          ) : null}

          {slice.primary.description ? (
            <div className="text-page__descritpion">
              <PrismicRichText field={slice.primary.description} />
            </div>
          ) : null}

          {slice.primary.date ? (
            <div className="text-page__date">
              <span className="text-page__date--label">
                Mis en ligne le {formattedDate}
              </span>
            </div>
          ) : null}

          {slice.primary.content ? (
            <div className="text-page__body">
              <PrismicRichText field={slice.primary.content} />
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default TextPage;
