import React from "react";

export const Plan = () => {
  return (
    <div className="journey__background--plan">
      <img
        className="journey__background--plan__ellipse"
        src="/illustrations/plans/ellipse.png"
        alt="Background Spectacles"
        sizes="600px"
      />
      <img
        className="journey__background--plan__frise"
        src="/illustrations/plans/frise-chronologique.png"
        alt="Background Spectacles"
        sizes="600px"
      />
      <img
        className="journey__background--plan__parcours"
        src="/illustrations/plans/parcours.png"
        alt="Background Spectacles"
        sizes="600px"
      />
      <img
        className="journey__background--plan__resto"
        src="/illustrations/plans/resto.png"
        alt="Background Spectacles"
        sizes="600px"
      />
    </div>
  );
};
