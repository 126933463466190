import { PrismicRichText } from '@prismicio/react';
import { useMediaQuery } from 'react-responsive';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React, { useState, useRef } from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';
import { Ruler } from '../../components/Ruler';

import { generateImageProps } from '../../helpers/image.js';

const modifiers = {
  default: null,
  heroWithoutImage: 'without-image',
  heroWithoutImageOrange: 'without-image-orange',
};

const SLIDE_INDEX_ATTRIBUTE_NAME = 'slide-index';

const Hero = ({ slice }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const scrollRef = useRef(null);

  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const [scrollBackgroundIndex, setScrollBackgroundIndex] = useState(0);

  const currentBackgroundIndex = isMobile
    ? scrollBackgroundIndex
    : backgroundIndex;

  const handleScroll = () => {
    if (scrollRef.current) {
      const elements = Array.from(
        scrollRef.current.querySelectorAll(`[${SLIDE_INDEX_ATTRIBUTE_NAME}]`)
      );

      for (const element of elements) {
        if (element.getBoundingClientRect().left > 200) {
          const index = parseInt(
            element.getAttribute(SLIDE_INDEX_ATTRIBUTE_NAME),
            10
          );

          setScrollBackgroundIndex(index);

          break;
        }
      }
    }
  };

  return (
    <div
      className={classnames(
        'hero',
        modifiers[slice.variation]
          ? `hero--${modifiers[slice.variation]}`
          : null
      )}
    >
      <div className="hero__background">
        <ul className="hero__background__images">
          {slice.items.map((item, index) => {
            return (
              <li
                className={classnames('hero__background__image', {
                  'hero__background__image--active':
                    index === currentBackgroundIndex,
                })}
                key={index}
              >
                <img
                  {...generateImageProps(item.image, {
                    width: 2000,
                    sizes: '100vw',
                  })}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="hero__gradient" />

      <MaxWidth>
        <div className="hero__container">
          <div className="hero__left">
            <div
              className="hero__title"
              dangerouslySetInnerHTML={{
                __html: prismicH
                  .asHTML(slice.primary.title)
                  ?.replace(/<\/h1><h1>/, '<br>'),
              }}
            />

            <div className="hero__description">
              {slice.primary.description ? (
                <PrismicRichText field={slice.primary.description} />
              ) : null}
            </div>

            <div className="hero__buttons">
              {slice.primary.buttonText ? (
                <Button
                  field={slice.primary.buttonLink}
                  leftIcon="ticket"
                  rightIcon="up-right-arrow"
                >
                  {slice.primary.buttonText ? (
                    <PrismicRichText field={slice.primary.buttonText} />
                  ) : null}
                </Button>
              ) : null}

              {slice.primary.buttonLightText ? (
                <Button
                  field={slice.primary.buttonLightLink}
                  theme="outline"
                  leftIcon="play"
                >
                  {slice.primary.buttonLightText ? (
                    <PrismicRichText field={slice.primary.buttonLightText} />
                  ) : null}
                </Button>
              ) : null}
            </div>
          </div>

          <div className="hero__right">
            <div className="hero__right__content">
              {slice.items.map((item, index) => {
                return (
                  <div
                    className={classnames('hero__right__content__item', {
                      'hero__right__content__item--active':
                        index === currentBackgroundIndex,
                    })}
                    key={index}
                  >
                    <Ruler />

                    {item.title ? (
                      <div className="hero__sub-title">
                        <PrismicRichText field={item.title} />
                      </div>
                    ) : null}

                    {item.description ? (
                      <div className="hero__sub-description">
                        <PrismicRichText field={item.description} />
                      </div>
                    ) : null}

                    {item.buttonText && item.buttonLink ? (
                      <Button
                        field={item.buttonLink}
                        theme="light"
                        rightIcon="right-arrow"
                      >
                        {item.buttonText ? (
                          <PrismicRichText field={item.buttonText} />
                        ) : null}
                      </Button>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="hero__right__slider">
              <div className="hero__right__slider__scroll">
                <div
                  className="hero__right__slider__wrap"
                  onScroll={handleScroll}
                  ref={scrollRef}
                >
                  {slice.items.map((item, index) => (
                    <div
                      className={classnames('hero__right__slider__slide', {
                        'hero__right__slider__slide--current':
                          index === currentBackgroundIndex,
                      })}
                      key={index}
                      {...{
                        [SLIDE_INDEX_ATTRIBUTE_NAME]: index,
                      }}
                    >
                      {item.image ? (
                        <img
                          {...generateImageProps(item.image, {
                            width: 600,
                            sizes: '228px',
                          })}
                        />
                      ) : null}

                      {item.buttonLink ? (
                        <Link
                          className="hero__right__slider__slide__link"
                          field={item.buttonLink}
                          onMouseOver={() => setBackgroundIndex(index)}
                        >
                          {item.title ? (
                            <PrismicRichText field={item.title} />
                          ) : null}
                        </Link>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Hero;
