import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import {
  ScheduleIcon,
  CompassIcon,
  PositionIcon,
} from '../../components/Icons';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image';

const InfosPratiques = ({ slice }) => {
  return (
    <div className="convenient">
      <MaxWidth>
        <div className="convenient__container">
          <div className="convenient__image">
            <div className="convenient__image-shadow">
              <img
                {...generateImageProps(slice.primary.mainImage, {
                  width: 1200,
                  sizes: '600px',
                })}
              />
            </div>
          </div>
          <div className="convenient__content">
            <div className="convenient__content__top">
              <div
                className={classnames('convenient__content__top__schedules', {
                  'convenient__content__top__schedules--disabled':
                    slice.primary.hideSchedules,
                })}
              >
                {slice.primary.titleSchedules ? (
                  <PrismicRichText field={slice.primary.titleSchedules} />
                ) : null}
                <div className="convenient__content__top__schedules__content">
                  <div className="convenient__content__top__schedules__icon">
                    <ScheduleIcon />
                  </div>
                  <div className="convenient__content__top__schedules__text">
                    {slice.items.map((item, index) => (
                      <div
                        className="convenient__content__top__schedules__item"
                        key={index}
                      >
                        {item.day ? (
                          <div className="convenient__content__top__schedules__item__day">
                            <PrismicRichText field={item.day} />
                          </div>
                        ) : null}
                        {item.schedules ? (
                          <div className="convenient__content__top__schedules__item__schedules">
                            <PrismicRichText field={item.schedules} />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="convenient__content__top__access">
                {slice.primary.titleAccess ? (
                  <PrismicRichText field={slice.primary.titleAccess} />
                ) : null}
                <div className="convenient__content__top__access__content">
                  <div className="convenient__content__top__access__icon">
                    <CompassIcon />
                  </div>
                  {slice.primary.contentAccess ? (
                    <div className="convenient__content__top__access__text">
                      <PrismicRichText field={slice.primary.contentAccess} />
                      <div className="convenient__content__top__access__link">
                        {slice.primary.linkTextAccess ? (
                          <Button
                            field={slice.primary.linkAccess}
                            horizontalPadding="large"
                            height="extra-small"
                            theme="transparent"
                          >
                            {slice.primary.linkTextAccess ? (
                              <PrismicRichText
                                field={slice.primary.linkTextAccess}
                              />
                            ) : null}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="convenient__content__position">
              {slice.primary.titlePosition ? (
                <PrismicRichText field={slice.primary.titlePosition} />
              ) : null}
              <div className="convenient__content__position__container">
                <div className="convenient__content__position__content">
                  <div className="convenient__content__position__icon">
                    <PositionIcon />
                  </div>
                  <div className="convenient__content__position__text">
                    {slice.primary.contentPosition ? (
                      <PrismicRichText field={slice.primary.contentPosition} />
                    ) : null}
                    {slice.primary.linkTextPosition ? (
                      <Button
                        field={slice.primary.linkPosition}
                        horizontalPadding="large"
                        height="extra-small"
                        theme="transparent"
                      >
                        {slice.primary.linkTextPosition ? (
                          <PrismicRichText
                            field={slice.primary.linkTextPosition}
                          />
                        ) : null}
                      </Button>
                    ) : null}
                  </div>
                </div>

                <div className="convenient__content__position__map">
                  <Link field={slice.primary.linkMap}>
                    <img
                      {...generateImageProps(slice.primary.map, {
                        width: 800,
                        sizes: '300px',
                      })}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default InfosPratiques;
