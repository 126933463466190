import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { MaxWidth } from '../../components/MaxWidth';

const Texte = ({ slice }) => {
  return (
    <div className="texte">
      <MaxWidth>
        {slice.primary.content ? (
          <PrismicRichText field={slice.primary.content} />
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default Texte;
