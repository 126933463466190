import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { MaxWidth } from '../../components/MaxWidth';
import { Numbers, NumbersItem } from '../../components/Numbers';
import { Text } from '../../components/Text';
import { Timeline } from '../../components/Timeline';
import { ChronologicalAnimation } from '../../components/ChronologicalAnimation';
import { Plan } from '../../components/Plan';
import { generateImageProps } from '../../helpers/image';
import { RichText } from 'prismic-reactjs';

const modifiers = {
  default: 'history',
  journeyFriseChronologique: 'timeline',
  journeyVr: 'vr',
  journeySpectacle: '360-show',
  journeyPlan: 'plan',
};

const Journey = ({ slice }) => {
  return (
    <div
      id={slice.primary.anchor}
      className={classnames(
        'journey',
        modifiers[slice.variation]
          ? `journey--variant-${modifiers[slice.variation]}`
          : null,
        {
          'light-primary': slice.primary.shade,
        }
      )}
    >
      {modifiers[slice.variation] === 'history' ? (
        <div className="journey__history-background">
          <div className="journey__history-background__wrap">
            {slice.items.map((date, index) => (
              <img
                key={index}
                className={`journey__history-background__wrap__image ${
                  index === 0 ? 'current' : ''
                }`}
                data-index={index}
                decoding="async"
                loading="lazy"
                {...generateImageProps(date.dateBackground, {
                  width: 1800,
                  sizes: '100vw',
                })}
              />
            ))}

            <div className="journey__history-background__gradient" />
          </div>
        </div>
      ) : null}

      {modifiers[slice.variation] === '360-show' ? (
        <div className="journey__360-show-background">
          <div className="journey__360-show-background__perspective">
            <div className="journey__360-show-background__transform">
              <div className="journey__360-show-background__wrap">
                <picture>
                  <source
                    srcSet="/illustrations/journey/360-show/background.avif"
                    type="image/avif"
                  />
                  <img
                    className="journey__360-show-background__image"
                    width="2000"
                    height="859"
                    decoding="async"
                    loading="lazy"
                    src="/illustrations/journey/360-show/background.jpg"
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>

          <div className="journey__360-show-background__gradient" />
        </div>
      ) : null}

      {modifiers[slice.variation] === 'timeline' ? (
        <div className="journey__timeline-background">
          <div className="journey__timeline-background__gradient" />

          <ChronologicalAnimation items={slice.items} />
        </div>
      ) : null}

      {modifiers[slice.variation] === 'vr' ? (
        <div className="journey__vr-background">
          <picture>
            <source
              srcSet="/illustrations/journey/vr/background.avif"
              type="image/avif"
            />
            <img
              width="2400"
              height="1031"
              decoding="async"
              loading="lazy"
              src="/illustrations/journey/vr/background.jpg"
              alt=""
            />
          </picture>

          <div className="journey__vr-background__gradient" />
        </div>
      ) : null}

      {modifiers[slice.variation] === 'plan' ? <Plan></Plan> : null}

      <MaxWidth>
        <div className="journey__content">
          {slice.primary.number ? (
            <div className="journey__number">
              <PrismicRichText field={slice.primary.number} />
            </div>
          ) : null}

          {modifiers[slice.variation] === 'vr' ? (
            <Heading
              supTitle={
                slice.primary.supTitle ? (
                  <PrismicRichText field={slice.primary.supTitle} />
                ) : null
              }
              title={
                slice.primary.title ? (
                  <h2>{RichText.asText(slice.primary.title)}</h2>
                ) : null
              }
              subTitle={
                slice.primary.subTitle ? (
                  <h3>{RichText.asText(slice.primary.subTitle)}</h3>
                ) : null
              }
            />
          ) : (
            <Heading
              title={
                slice.primary.title ? (
                  <h2>{RichText.asText(slice.primary.title)}</h2>
                ) : null
              }
              subTitle={
                slice.primary.subTitle ? (
                  <h3>{RichText.asText(slice.primary.subTitle)}</h3>
                ) : null
              }
            />
          )}

          {slice.primary.description ? (
            <Text>
              <PrismicRichText field={slice.primary.description} />
            </Text>
          ) : null}

          {slice.primary.buttonText && slice.primary.buttonLink ? (
            <Button field={slice.primary.buttonLink} rightIcon="up-right-arrow">
              {slice.primary.buttonText ? (
                <PrismicRichText field={slice.primary.buttonText} />
              ) : null}
            </Button>
          ) : null}

          <Numbers>
            <NumbersItem
              title={<PrismicRichText field={slice.primary.dataOne} />}
              description={
                <PrismicRichText field={slice.primary.dataDescriptionOne} />
              }
            />

            <NumbersItem
              title={<PrismicRichText field={slice.primary.dataTwo} />}
              description={
                <PrismicRichText field={slice.primary.dataDescriptionTwo} />
              }
            />

            <NumbersItem
              title={<PrismicRichText field={slice.primary.dataThree} />}
              description={
                <PrismicRichText field={slice.primary.dataDescriptionThree} />
              }
            />
          </Numbers>
        </div>

        {modifiers[slice.variation] === 'history' ? (
          <Timeline itemsLength={slice.items.length} slice={slice}></Timeline>
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default Journey;
