import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import truncate from 'truncate';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

import { isImage, generateImageProps } from '../../helpers/image.js';

const ARTICLES_PER_SECTION = 6;

const LastArticles = ({ context }) => {
  const sections = useMemo(() => {
    const list = context.articles
      .slice()
      .filter((article) => {
        return article.data.index || process.env.NODE_ENV === 'development';
      })
      .sort((a, b) => {
        return new Date(b.data.publishDate) - new Date(a.data.publishDate);
      });

    return list.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / ARTICLES_PER_SECTION);

      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);

      return result;
    }, []);
  }, [context.articles]);

  return (
    <div className="last-articles">
      <MaxWidth>
        <div className="last-articles__container">
          {sections.map((articles, sectionIndex) => {
            return (
              <div
                className="last-articles__container__section"
                key={sectionIndex}
              >
                {articles.map((article, index) => {
                  return (
                    <div
                      className={classnames('last-articles__item', {
                        'last-articles__item--with-background':
                          index % ARTICLES_PER_SECTION === 0,
                        'last-articles__item--background-black':
                          sectionIndex % 2 === 0 &&
                          index % ARTICLES_PER_SECTION === 0,
                        'last-articles__item--background-grey':
                          sectionIndex % 2 === 1 &&
                          index % ARTICLES_PER_SECTION === 0,
                      })}
                      key={article.uid}
                    >
                      <div className="last-articles__item__image">
                        {isImage(article.data.image) ? (
                          <img
                            {...generateImageProps(article.data.image, {
                              width: 800,
                              sizes: '600px',
                            })}
                          />
                        ) : null}
                      </div>

                      <div className="last-articles__item__content">
                        <div className="last-articles__item__content__top">
                          <p className="last-articles__item__content__sup-title">
                            {prismicH.asText(article.data.supTitle)}
                          </p>

                          <h2 className="last-articles__item__content__title">
                            <Link
                              field={{
                                id: article.id,
                                type: article.type,
                                lang: article.lang,
                                uid: article.uid,
                                link_type: 'Document',
                                isBroken: false,
                              }}
                            >
                              {prismicH.asText(article.data.title)}
                            </Link>
                          </h2>

                          <div
                            className={classnames(
                              'last-articles__item__content__description',
                              {
                                'last-articles__item__content__description--truncate-3':
                                  index % ARTICLES_PER_SECTION !== 0,
                                'last-articles__item__content__description--truncate-5':
                                  index % ARTICLES_PER_SECTION === 0,
                              }
                            )}
                          >
                            <p>{prismicH.asText(article.data.subTitle)}</p>

                            <p>
                              {truncate(
                                prismicH.asText(article.data.content),
                                260
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="last-articles__item__content__bottom">
                          <div className="last-articles__item__content__button">
                            <Button
                              theme={
                                index === 0 && sectionIndex % 2 === 0
                                  ? 'light-white'
                                  : 'light-blue'
                              }
                              rightIcon="right-arrow"
                            >
                              En savoir plus
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};
export default LastArticles;
