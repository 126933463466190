import React from 'react';
import classnames from 'classnames';
import { PrismicRichText } from '@prismicio/react';

import { MaxWidth } from '../../components/MaxWidth';
import { Numbers, NumbersItem } from '../../components/Numbers';

import { generateImageProps } from '../../helpers/image';
import { RichText } from 'prismic-reactjs';

const ImageText = ({ slice, slices }) => {
  const isFirst = slices.length > 0 && slices[0].id === slice.id;
  return (
    <div
      id={slice.primary.anchor}
      className={classnames('image-text', {
        'image-text--reverse': slice.primary.reverse,
        'image-text--first': isFirst,
        'image-text--background-light-blue-to-transparent':
          slice.variation !== 'imageTextBanner' &&
          slice.primary.backgroundType === 'light blue to transparent',
        'image-text-banner': slice.variation === 'imageTextBanner',
      })}
    >
      {slice.variation === 'imageTextBanner' && (
        <div className="image-text__gradient"></div>
      )}
      <MaxWidth>
        <div className="image-text__container">
          <div
            className={classnames('image-text__image', {
              'image-text__image--double': slice.items.length === 2,
              'image-text__image--double-vertical':
                slice.items.length === 2 && slice.primary.imageDirection,
              'image-text__image--gallery': slice.items.length > 2,
            })}
          >
            {slice.items.map((item, index) => {
              return (
                <div className="image-text__item" key={index}>
                  <img
                    {...generateImageProps(item.image, {
                      width: 1200,
                      sizes: '600px',
                    })}
                  />
                </div>
              );
            })}
          </div>

          <div className="image-text__content">
            {slice.primary.supTitle ? (
              <div className="image-text__sup-title">
                <PrismicRichText field={slice.primary.supTitle} />
              </div>
            ) : null}
            {slice.primary.title ? (
              <div className="image-text__title">
                {isFirst ? (
                  <h1>{RichText.asText(slice.primary.title)}</h1>
                ) : (
                  <h2>{RichText.asText(slice.primary.title)}</h2>
                )}
              </div>
            ) : null}

            {slice.primary.paragraph ? (
              <div className="image-text__paragraph">
                <PrismicRichText field={slice.primary.paragraph} />
              </div>
            ) : null}
            {slice.variation !== 'imageTextBanner' && (
              <div className="image-text__numbers">
                <Numbers>
                  <NumbersItem
                    title={<PrismicRichText field={slice.primary.dataOne} />}
                    description={
                      <PrismicRichText
                        field={slice.primary.dataOneDescription}
                      />
                    }
                  />

                  <NumbersItem
                    title={<PrismicRichText field={slice.primary.dataTwo} />}
                    description={
                      <PrismicRichText
                        field={slice.primary.dataTwoDescription}
                      />
                    }
                  />

                  <NumbersItem
                    title={<PrismicRichText field={slice.primary.dataThree} />}
                    description={
                      <PrismicRichText
                        field={slice.primary.dataThreeDescription}
                      />
                    }
                  />
                </Numbers>
              </div>
            )}
          </div>

          {slice.variation === 'imageTextBanner' && (
            <div className="image-text__numbers">
              <div className="image-text__numbers__grid">
                <NumbersItem
                  title={<PrismicRichText field={slice.primary.dataOne} />}
                  description={
                    <PrismicRichText field={slice.primary.dataOneDescription} />
                  }
                />

                <NumbersItem
                  title={<PrismicRichText field={slice.primary.dataTwo} />}
                  description={
                    <PrismicRichText field={slice.primary.dataTwoDescription} />
                  }
                />
              </div>
              <NumbersItem
                title={<PrismicRichText field={slice.primary.dataThree} />}
                description={
                  <PrismicRichText field={slice.primary.dataThreeDescription} />
                }
              />
            </div>
          )}
        </div>
      </MaxWidth>

      {slice.variation === 'imageTextBanner' && (
        <div className="image-text__banner">
          <img
            {...generateImageProps(slice.primary.banner, {
              width: 2000,
              sizes: '100vw',
            })}
          />
        </div>
      )}
    </div>
  );
};

export default ImageText;
