import React from 'react';

export const TicketIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M9.40039 6.16262V4.54674C9.08317 4.54674 8.82012 4.29121 8.82012 3.98307V2.05904C8.82012 1.80276 8.61122 1.60059 8.34816 1.60059H3.03288C1.3617 1.60059 0 2.9226 0 4.54674V6.49256C0 6.64363 0.0618956 6.78642 0.170213 6.89164C0.27853 6.99611 0.425532 7.05624 0.580271 7.05624C1.16828 7.05624 1.61702 7.46209 1.61702 7.9957C1.61702 8.55261 1.16054 8.99604 0.588008 9.00281C0.270793 9.00281 0 9.22076 0 9.53642V11.4612C0 13.0846 1.3617 14.3998 3.02515 14.3998H8.34816C8.61122 14.3998 8.82012 14.1962 8.82012 13.9414V12.3172C8.82012 12.0023 9.08317 11.7536 9.40039 11.7536V10.2963C9.08317 10.2963 8.82012 10.04 8.82012 9.73258V6.7263C8.82012 6.41815 9.08317 6.16262 9.40039 6.16262"
        fill="#0D2441"
      />
      <path
        d="M14.383 7.99596C14.383 8.55213 14.8472 8.99555 15.412 9.00307C15.7292 9.00307 16 9.22102 16 9.52917V11.4607C16 13.0848 14.646 14.4001 12.9749 14.4001H10.4526C10.1973 14.4001 9.98066 14.1964 9.98066 13.9409V12.3175C9.98066 12.0018 9.72534 11.7538 9.40039 11.7538V10.2965C9.72534 10.2965 9.98066 10.0402 9.98066 9.73284V6.72656C9.98066 6.41767 9.72534 6.16288 9.40039 6.16288V4.54625C9.72534 4.54625 9.98066 4.29072 9.98066 3.98258V2.05931C9.98066 1.80302 10.1973 1.6001 10.4526 1.6001H12.9749C14.646 1.6001 16 2.91535 16 4.53874V6.4327C16 6.58301 15.9381 6.72656 15.8298 6.83103C15.7215 6.93625 15.5745 6.99637 15.4197 6.99637C14.8472 6.99637 14.383 7.44807 14.383 7.99596"
        fill="#0D2441"
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.1C0.502944 6.1 0.1 6.50294 0.1 7C0.1 7.49706 0.502944 7.9 1 7.9V6.1ZM15.6364 7.6364C15.9879 7.28492 15.9879 6.71508 15.6364 6.3636L9.90883 0.636039C9.55736 0.284567 8.98751 0.284567 8.63604 0.636039C8.28457 0.987511 8.28457 1.55736 8.63604 1.90883L13.7272 7L8.63604 12.0912C8.28457 12.4426 8.28457 13.0125 8.63604 13.364C8.98751 13.7154 9.55736 13.7154 9.90883 13.364L15.6364 7.6364ZM1 7.9H15V6.1H1V7.9Z"
        fill="#DFA244"
      />
    </svg>
  );
};

export const RightArrowSquare = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M6.59699 18.8333H13.4037C16.3528 18.8333 18.3337 16.9366 18.3337 14.1124V6.88742C18.3337 4.06325 16.3528 2.16659 13.4037 2.16659H6.59699C3.64866 2.16659 1.66699 4.06409 1.66699 6.88825L1.66699 14.1124C1.66699 16.9366 3.64866 18.8333 6.59699 18.8333"
        fill="#0D2441"
      />
      <path
        d="M10.7128 14.0661L13.8503 10.9427C14.0861 10.7077 14.0861 10.2919 13.8503 10.0561L10.7128 6.93273C10.4678 6.6894 10.072 6.69023 9.82863 6.93523C9.5853 7.18023 9.5853 7.57523 9.8303 7.8194L11.8945 9.87523H6.59863C6.2528 9.87523 5.97363 10.1552 5.97363 10.5002C5.97363 10.8452 6.2528 11.1252 6.59863 11.1252H11.8945L9.8303 13.1802C9.7078 13.3027 9.64697 13.4627 9.64697 13.6236C9.64697 13.7827 9.7078 13.9427 9.82863 14.0644C10.072 14.3086 10.4678 14.3094 10.7128 14.0661"
        fill="#0D2441"
      />
    </svg>
  );
};

export const UpRightArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1588 0.0452131C14.9131 0.0452142 15.5246 0.656709 15.5246 1.41103L15.5246 13.7033C15.5246 14.4577 14.9131 15.0692 14.1588 15.0692C13.4045 15.0692 12.793 14.4577 12.793 13.7033L12.793 4.70839L2.37698 15.1244C1.84359 15.6578 0.97881 15.6578 0.445427 15.1244C-0.087956 14.591 -0.0879556 13.7262 0.445427 13.1928L10.8614 2.77684L1.86648 2.77684C1.11216 2.77683 0.500663 2.16534 0.500663 1.41102C0.500664 0.656704 1.11216 0.045209 1.86648 0.045209L14.1588 0.0452131Z"
        fill="#0D2441"
      />
    </svg>
  );
};

export const PlayIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16 8.33711C16 12.7443 12.4093 16.3333 8 16.3333C3.59073 16.3333 0 12.7443 0 8.33711C0 3.92225 3.59073 0.333252 8 0.333252C12.4093 0.333252 16 3.92225 16 8.33711"
        fill="white"
      />
      <path
        d="M11.2001 8.33719C11.2001 8.53938 11.1365 8.74234 11.0092 8.90487C10.9853 8.93675 10.874 9.06817 10.7865 9.15371L10.7387 9.20037C10.0706 9.9088 8.40805 10.9742 7.56487 11.3156C7.56487 11.3233 7.06373 11.5263 6.8251 11.5333H6.79328C6.42737 11.5333 6.08532 11.3319 5.91032 11.0053C5.81487 10.8256 5.72737 10.3046 5.71942 10.2976C5.64782 9.83026 5.6001 9.11483 5.6001 8.32941C5.6001 7.50589 5.64782 6.75857 5.73532 6.29976C5.73532 6.29199 5.82282 5.87206 5.87851 5.73209C5.96601 5.53068 6.1251 5.35882 6.32396 5.24995C6.48305 5.17296 6.6501 5.1333 6.8251 5.1333C7.00805 5.14185 7.3501 5.25772 7.48533 5.31216C8.37623 5.65432 10.0785 6.77413 10.7308 7.45845C10.8421 7.56732 10.9615 7.7003 10.9933 7.73063C11.1285 7.90171 11.2001 8.11167 11.2001 8.33719"
        fill="white"
      />
    </svg>
  );
};

export const MoreIcon = () => {
  return (
    <svg
      width="255"
      height="249"
      viewBox="0 0 255 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M233.754 124.27C233.754 181.376 186.186 227.648 127.504 227.648C68.822 227.648 21.2539 181.376 21.2539 124.27C21.2539 67.1842 68.822 20.8914 127.504 20.8914C186.186 20.8914 233.754 67.1842 233.754 124.27"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9153 111.9C72.8921 111.9 67.1865 117.452 67.1865 124.264C67.1865 131.087 72.8921 136.649 79.9153 136.649C86.9384 136.649 92.644 131.087 92.644 124.264C92.644 117.452 86.9384 111.9 79.9153 111.9ZM127.504 111.9C120.481 111.9 114.775 117.452 114.775 124.264C114.775 131.087 120.481 136.649 127.504 136.649C134.527 136.649 140.232 131.087 140.232 124.264C140.232 117.452 134.527 111.9 127.504 111.9ZM162.363 124.264C162.363 117.452 168.069 111.9 175.092 111.9C182.115 111.9 187.821 117.452 187.821 124.264C187.821 131.087 182.115 136.649 175.092 136.649C168.069 136.649 162.363 131.087 162.363 124.264Z"
        fill="white"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="334"
      height="338"
      viewBox="0 0 334 338"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M306.172 168.876C306.172 246.618 243.867 309.61 167.006 309.61C90.1438 309.61 27.8389 246.618 27.8389 168.876C27.8389 91.1631 90.1438 28.1425 167.006 28.1425C243.867 28.1425 306.172 91.1631 306.172 168.876"
        fill="#0C2441"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.107 177.756C179.107 184.539 173.638 190.07 166.93 190.07C160.222 190.07 154.753 184.539 154.753 177.756V115.552C154.753 108.768 160.222 103.237 166.93 103.237C173.638 103.237 179.107 108.768 179.107 115.552V177.756ZM154.818 222.407C154.818 215.624 160.26 210.093 166.926 210.093C173.787 210.093 179.242 215.624 179.242 222.407C179.242 229.191 173.787 234.722 167.065 234.722C160.315 234.722 154.818 229.191 154.818 222.407Z"
        fill="#0D2441"
      />
    </svg>
  );
};

export const ScheduleIcon = () => {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icone/Horraire">
        <g id="Time Circle">
          <path
            id="Fill 1"
            opacity="0.4"
            d="M139.336 76.0006C139.336 110.986 110.981 139.334 76.0023 139.334C41.0233 139.334 12.6689 110.986 12.6689 76.0006C12.6689 41.0279 41.0233 12.6672 76.0023 12.6672C110.981 12.6672 139.336 41.0279 139.336 76.0006"
            fill="white"
          />
          <path
            id="Fill 4"
            d="M98.6396 100.158C97.8099 100.158 96.9739 99.943 96.2076 99.4933L71.3429 84.6607C69.9116 83.7993 69.0312 82.2477 69.0312 80.5757V48.6113C69.0312 45.9893 71.1592 43.8613 73.7812 43.8613C76.4032 43.8613 78.5312 45.9893 78.5312 48.6113V77.8777L101.078 91.3233C103.326 92.6723 104.067 95.5857 102.725 97.8403C101.832 99.3287 100.255 100.158 98.6396 100.158"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export const CompassIcon = () => {
  return (
    <svg
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icone/Boussole">
        <g id="Discovery">
          <path
            id="Fill 1"
            opacity="0.4"
            d="M131.086 71.5006C131.086 104.408 104.41 131.084 71.5023 131.084C38.5944 131.084 11.9189 104.408 11.9189 71.5006C11.9189 38.5987 38.5944 11.9172 71.5023 11.9172C104.41 11.9172 131.086 38.5987 131.086 71.5006"
            fill="white"
          />
          <path
            id="Fill 4"
            d="M94.5008 51.8673L84.8483 82.374C84.4908 83.6252 83.4779 84.6381 82.2266 85.0016L51.8391 94.529C49.8133 95.1903 47.847 93.2181 48.5024 91.1923L58.0358 60.626C58.3933 59.3748 59.4062 58.4215 60.6574 58.0044L91.1641 48.471C93.2495 47.8156 95.1562 49.7819 94.5008 51.8673"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export const PositionIcon = () => {
  return (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icone/Position">
        <g id="Location">
          <path
            id="Path_34175"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.1698 19.3346C70.5534 10.977 88.2333 11.1231 102.482 19.7172C116.591 28.4863 125.165 44.1366 125.085 60.972C124.757 77.6969 115.562 93.4183 104.069 105.572C97.4355 112.618 90.0146 118.849 81.9581 124.136C81.1284 124.616 80.2195 124.937 79.2764 125.084C78.3686 125.046 77.4846 124.777 76.704 124.304C64.4042 116.358 53.6135 106.217 44.8511 94.3661C37.519 84.474 33.3533 72.5229 32.9199 60.1357C32.9104 43.2681 41.7861 27.6921 56.1698 19.3346ZM64.48 67.1155C66.8995 73.0803 72.6105 76.9711 78.9464 76.9712C83.0972 77.001 87.0873 75.3383 90.0276 72.3538C92.9678 69.3692 94.614 65.3107 94.5993 61.0825C94.6214 54.6285 90.8218 48.7975 84.9745 46.3121C79.1273 43.8266 72.3858 45.177 67.8977 49.7326C63.4097 54.2883 62.0605 61.1506 64.48 67.1155Z"
            fill="white"
          />
          <ellipse
            id="Ellipse_743"
            opacity="0.4"
            cx="79.0055"
            cy="138.251"
            rx="32.9167"
            ry="6.58333"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="128" cy="128" r="128" fill="white" fillOpacity="0.2" />
      <path
        d="M117.379 172.498V139.17H85.333V118.661H117.379V85.3335H138.621V118.661H170.666V139.17H138.621V172.498H117.379Z"
        fill="white"
      />
    </svg>
  );
};

export const SubtractIcon = () => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="128" cy="128" r="128" fill="white" fillOpacity="0.2" />
      <path
        d="M117.379 139.169H85.3335V118.66H138.621H170.667V139.169H117.379Z"
        fill="white"
      />
    </svg>
  );
};

export const MetroIcon = () => {
  return (
    <svg
      width="97"
      height="135"
      viewBox="0 0 97 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 37.7222C0 16.8888 16.8888 0 37.7222 0H64.6667C82.5239 0 97 14.4761 97 32.3333V86.3067C97 92.2591 92.1746 97.0845 86.2222 97.0845H10.7778C4.82538 97.0845 0 92.2591 0 86.3067V37.7222Z"
        fill="white"
      />
      <ellipse
        cx="23.2399"
        cy="77.8686"
        rx="7.07292"
        ry="7.07908"
        fill="#24384F"
      />
      <ellipse
        cx="72.7497"
        cy="77.8686"
        rx="7.07292"
        ry="7.07908"
        fill="#24384F"
      />
      <path
        d="M12.125 30.6704C12.125 24.718 16.9504 19.8926 22.9028 19.8926H73.0868C79.0392 19.8926 83.8646 24.718 83.8646 30.6704V47.8765C83.8646 50.8527 81.4519 53.2654 78.4757 53.2654H17.5139C14.5377 53.2654 12.125 50.8527 12.125 47.8765V30.6704Z"
        fill="#24384F"
      />
      <rect
        x="13.1357"
        y="109.22"
        width="68.7083"
        height="7.07908"
        rx="3.53954"
        fill="white"
      />
      <rect
        width="48.6532"
        height="7.07424"
        rx="3.53712"
        transform="matrix(0.463766 -0.885958 0.885625 0.4644 1.5791 131.088)"
        fill="white"
      />
      <rect
        width="48.8444"
        height="7.07412"
        rx="3.53706"
        transform="matrix(-0.441234 -0.897392 0.897087 -0.441853 87.2539 134.722)"
        fill="white"
      />
    </svg>
  );
};
