import React, { useEffect, useRef, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import smoothscroll from 'smoothscroll-polyfill';

const AUTOPLAY_DURATION = 4000;
const LINEUNIT_DESKTOP = 220;
const LINEUNIT_MOBILE = 230;

export const TimelineItem = ({
  date = null,
  title = null,
  description = null,
  index = 0,
  setIndex,
  setAutoplayPause,
}) => {
  return (
    <li
      className={`timeline-item ${index === 0 ? 'current' : ''}`}
      data-index={index}
      onMouseEnter={() => setAutoplayPause(true)}
      onMouseLeave={() => setAutoplayPause(false)}
    >
      <button className="timeline-item__button" onClick={() => setIndex(index)}>
        <span className="visually-hidden">Voir la date</span>
      </button>

      <div className="timeline-item__circle" />

      <div className="timeline-item__date">{date}</div>

      <div className="timeline-item__title">{title}</div>

      <div className="timeline-item__description">{description}</div>
    </li>
  );
};

export const Timeline = ({
  itemsLength = 0,
  slice = null,
  timeLineRef = useRef(null),
  lineRef = useRef(null),
  lineCurrentRef = useRef(null),
  listRef = useRef(null),
}) => {
  const switchTimeline = (i, lineUnit) => {
    const current = document.querySelector(`.timeline-item[data-index="${i}"]`);
    const currentBackground = document.querySelector(
      `.journey__history-background__wrap__image[data-index="${i}"]`
    );
    const old = document.querySelector('.timeline-item.current');
    const oldBackground = document.querySelector(
      '.journey__history-background__wrap__image.current'
    );

    if (!current || !currentBackground || !old || !oldBackground) return;

    const oldIndex = parseInt(old.dataset.index);

    if (oldIndex === i) return;

    old.classList.remove('current');
    oldBackground.classList.remove('current');

    /** TODO: plutot utiliser une valeur en `calc(var(--item-size) * ${i})` */

    lineCurrentRef.current.style.width = `${lineUnit * i}px`;
    lineRef.current.style.width = `${
      lineUnit *
      (i + (i >= Object.keys(slice.items || {}).length - 1 ? 1.6 : 3))
    }px`;

    setTimeout(function () {
      /* if (oldIndex > 1 || i > 1) */
      timeLineRef.current.style.transform = `translate3d(-${
        (i === 0 ? 0 : i - 1) * lineUnit
      }px,0,0)`;

      setTimeout(function () {
        current.classList.add('current');
        currentBackground.classList.add('current');

        const objs = [
          { index: -1, opacity: 'unset' },
          { index: 0, opacity: 'unset' },
          { index: 1, opacity: 'unset' },
          { index: 2, opacity: '0.2' },
          { index: 3, opacity: '0.2' },
        ];

        objs.forEach((obj) => {
          const e = document.querySelector(
            `.timeline-item[data-index="${
              (i === 0 ? i + 1 : i) + obj.index
            }"] .timeline-item__circle`
          );
          if (e) {
            e.parentElement.style.opacity = obj.opacity;
          }
        });
      }, 400);
    }, 200);
  };

  const handleScroll = (event, i = -1) => {
    const scroll =
      parseInt(event.currentTarget.scrollLeft) + (LINEUNIT_MOBILE - 50);
    i =
      parseInt(event.currentTarget.scrollLeft) < 50
        ? 0
        : parseInt(scroll / LINEUNIT_MOBILE) > itemsLength - 1
        ? itemsLength - 1
        : parseInt(scroll / LINEUNIT_MOBILE);

    const current = document.querySelector(`.timeline-item[data-index="${i}"]`);
    const currentBackground = document.querySelector(
      `.journey__history-background__wrap__image[data-index="${i}"]`
    );
    const old = document.querySelector('.timeline-item.current');
    const oldBackground = document.querySelector(
      '.journey__history-background__wrap__image.current'
    );

    if (
      !current ||
      !currentBackground ||
      !old ||
      !oldBackground ||
      !lineCurrentRef.current
    )
      return;

    const oldIndex = parseInt(old.dataset.index);

    if (oldIndex === i) return;

    old.classList.remove('current');
    oldBackground.classList.remove('current');
    current.classList.add('current');
    currentBackground.classList.add('current');
    lineCurrentRef.current.style.width = `${LINEUNIT_MOBILE * i}px`;
    lineRef.current.style.width = `${
      LINEUNIT_MOBILE * (Object.keys(slice.items || {}).length + 1)
    }px`;
  };

  const [autoplayPause, setAutoplayPause] = useState(false);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const lineUnit = isMobile ? LINEUNIT_MOBILE : LINEUNIT_DESKTOP;
    switchTimeline(index, lineUnit);
    if (isMobile) {
      timeLineRef.current.scrollTo({
        left: lineUnit * index,
        behavior: 'smooth',
      });
    }
  }, [index]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!autoplayPause && !window.matchMedia('(max-width: 768px)').matches) {
        setIndex((index) => {
          return index >= Object.keys(slice.items || {}).length ? 0 : index + 1;
        });
      }
    }, AUTOPLAY_DURATION);

    return () => {
      clearInterval(interval);
    };
  }, [autoplayPause]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div className="timeline-container">
      <div className="timeline" onScroll={handleScroll} ref={timeLineRef}>
        <div className="timeline__line" ref={lineRef} />
        <div className="timeline__current-line" ref={lineCurrentRef} />

        <ul className="timeline__list" ref={listRef}>
          {slice.items.map((date, index) => (
            <TimelineItem
              key={index}
              setIndex={setIndex}
              setAutoplayPause={setAutoplayPause}
              date={date.date ? <PrismicRichText field={date.date} /> : null}
              title={
                date.dateTitle ? (
                  <PrismicRichText field={date.dateTitle} />
                ) : null
              }
              index={index}
              itemsLength={slice.items.length}
              description={
                date.dateDescription ? (
                  <PrismicRichText field={date.dateDescription} />
                ) : null
              }
              timeLineRef={timeLineRef}
              lineCurrentRef={lineCurrentRef}
              lineRef={lineRef}
              handleScroll={handleScroll}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
