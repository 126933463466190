import React from 'react';
import classnames from 'classnames';

import { Link } from '../Link';
import { TicketIcon, UpRightArrow, RightArrow, PlayIcon } from '../Icons';

import { isLink } from '../../helpers/is-link.js';

export const Button = ({
  as = 'button',
  field = null,
  height = 'normal',
  horizontalPadding = 'normal',
  theme = 'primary',
  leftIcon = null,
  rightIcon = null,
  children,
  disabled = false,
  ...rest
}) => {
  const El = field ? Link : as;

  const isDisabled = field && !isLink(field) ? true : disabled;

  return (
    <El
      className={classnames(
        'button',
        `button--height-${height}`,
        `button--horizontal-padding-${horizontalPadding}`,
        `button--theme-${theme}`,
        {
          'button--disabled': isDisabled,
        }
      )}
      field={El === Link ? field : null}
      disabled={El === 'button' ? isDisabled : null}
      {...rest}
    >
      <span className="button__background"></span>

      <span className="button__content">
        {leftIcon ? (
          <span className="button__left-icon">
            {leftIcon === 'play' ? <PlayIcon /> : null}
            {leftIcon === 'ticket' ? <TicketIcon /> : null}
          </span>
        ) : null}

        <span className="button__text">{children}</span>

        {rightIcon ? (
          <span className="button__right-icon">
            {rightIcon === 'up-right-arrow' ? <UpRightArrow /> : null}
            {rightIcon === 'right-arrow' ? <RightArrow /> : null}
          </span>
        ) : null}
      </span>
    </El>
  );
};
