import { PrismicRichText } from '@prismicio/react';
import { RichText } from 'prismic-reactjs';
import React from 'react';

import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { MaxWidth } from '../../components/MaxWidth';
import { Text } from '../../components/Text';

import { generateImageProps } from '../../helpers/image.js';

const ICONS = {
  metro: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.125 21.5925C26.125 23.935 24.2275 25.8337 21.8838 25.8337H7.24C4.8975 25.8337 3 23.935 3 21.5925V18.2175L3.00125 9.24125C3.00125 6.89875 4.9 5 7.2425 5H21.8825C24.225 5 26.1225 6.89875 26.1225 9.24125L26.125 18.2175V21.5925Z"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19.0717V11H13.2849L14.5019 14.3032C14.5764 14.5267 14.6467 14.7585 14.713 14.9986C14.7792 15.2387 14.8496 15.4787 14.9241 15.7188H14.9737C15.0483 15.4787 15.1186 15.2387 15.1848 14.9986C15.2511 14.7585 15.3214 14.5267 15.396 14.3032L16.5632 11H18.8481V19.0717H16.9109V16.6874C16.9109 16.4142 16.9275 16.1079 16.9606 15.7685C16.9937 15.4208 17.031 15.0731 17.0724 14.7254C17.1138 14.3777 17.151 14.0714 17.1841 13.8065H17.1345L16.4639 15.7437L15.4705 18.4135H14.328L13.3346 15.7437L12.6888 13.8065H12.6392C12.6723 14.0714 12.7095 14.3777 12.7509 14.7254C12.7923 15.0731 12.8296 15.4208 12.8627 15.7685C12.8958 16.1079 12.9124 16.4142 12.9124 16.6874V19.0717H11Z"
        fill="#D8BD73"
      />
    </svg>
  ),
  rer: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.563 21.2798C26.563 23.6223 24.6655 25.521 22.3217 25.521H7.67799C5.33549 25.521 3.43799 23.6223 3.43799 21.2798V17.9048L3.43924 8.92851C3.43924 6.58601 5.33799 4.68726 7.68049 4.68726H22.3205C24.663 4.68726 26.5605 6.58601 26.5605 8.92851L26.563 17.9048V21.2798Z"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17.9512V12H9.2523C9.67957 12 10.0733 12.058 10.4334 12.174C10.7935 12.2838 11.0834 12.4791 11.3032 12.7599C11.5229 13.0407 11.6328 13.4283 11.6328 13.9227C11.6328 14.35 11.5443 14.704 11.3673 14.9848C11.1903 15.2655 10.9583 15.4853 10.6714 15.644L11.9624 17.9512H10.2045L9.17905 15.9553H8.57478V17.9512H7ZM8.57478 14.7101H9.14243C9.77723 14.7101 10.0946 14.4476 10.0946 13.9227C10.0946 13.6663 10.0122 13.4893 9.84742 13.3917C9.68872 13.294 9.45373 13.2452 9.14243 13.2452H8.57478V14.7101Z"
        fill="#D8BD73"
      />
      <path
        d="M12.8586 17.9512V12H16.704V13.3184H14.4333V14.2523H16.3744V15.5707H14.4333V16.6328H16.7955V17.9512H12.8586Z"
        fill="#D8BD73"
      />
      <path
        d="M18.0376 17.9512V12H20.2899C20.7172 12 21.1109 12.058 21.471 12.174C21.8311 12.2838 22.1211 12.4791 22.3408 12.7599C22.5605 13.0407 22.6704 13.4283 22.6704 13.9227C22.6704 14.35 22.5819 14.704 22.4049 14.9848C22.2279 15.2655 21.9959 15.4853 21.709 15.644L23 17.9512H21.2421L20.2167 15.9553H19.6124V17.9512H18.0376ZM19.6124 14.7101H20.18C20.8148 14.7101 21.1322 14.4476 21.1322 13.9227C21.1322 13.6663 21.0498 13.4893 20.885 13.3917C20.7263 13.294 20.4913 13.2452 20.18 13.2452H19.6124V14.7101Z"
        fill="#D8BD73"
      />
    </svg>
  ),
  tarifs: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3217 25.521C24.6655 25.521 26.563 23.6223 26.563 21.2798V17.9048C25.013 17.9048 23.763 16.6548 23.763 15.106C23.763 13.556 25.013 12.3073 26.563 12.3073L26.5605 8.92851C26.5605 6.58601 24.663 4.68726 22.3205 4.68726H7.68049C5.33799 4.68726 3.43924 6.58601 3.43924 8.92851L3.43799 12.416C4.98674 12.416 6.23674 13.556 6.23674 15.106C6.23674 16.6548 4.98674 17.9048 3.43799 17.9048V21.2798C3.43799 23.6223 5.33549 25.521 7.67799 25.521H22.3217Z"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 17.3179L17.1749 18.4617C17.3786 18.5679 17.6174 18.3954 17.5786 18.1692L17.1624 15.7454L18.9236 14.0317C19.0886 13.8704 18.9974 13.5917 18.7699 13.5579L16.3374 13.2042L15.2486 10.9992C15.1474 10.7929 14.8536 10.7929 14.7511 10.9992L13.6624 13.2042L11.2311 13.5579C11.0036 13.5917 10.9124 13.8704 11.0774 14.0317L12.8374 15.7454L12.4211 18.1692C12.3824 18.3954 12.6211 18.5679 12.8249 18.4617L14.9999 17.3179Z"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  dates: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.86621 11.7553H26.1461"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51025 16.6371C8.51025 16.1193 8.92999 15.6996 9.44775 15.6996H9.45933C9.9771 15.6996 10.3968 16.1193 10.3968 16.6371C10.3968 17.1549 9.9771 17.5746 9.45933 17.5746H9.44775C8.92999 17.5746 8.51025 17.1549 8.51025 16.6371ZM15.0062 15.6996C14.4885 15.6996 14.0687 16.1193 14.0687 16.6371C14.0687 17.1549 14.4885 17.5746 15.0062 17.5746H15.0178C15.5356 17.5746 15.9553 17.1549 15.9553 16.6371C15.9553 16.1193 15.5356 15.6996 15.0178 15.6996H15.0062ZM20.5531 15.6996C20.0353 15.6996 19.6156 16.1193 19.6156 16.6371C19.6156 17.1549 20.0353 17.5746 20.5531 17.5746H20.5647C21.0824 17.5746 21.5022 17.1549 21.5022 16.6371C21.5022 16.1193 21.0824 15.6996 20.5647 15.6996H20.5531ZM20.5531 20.5577C20.0353 20.5577 19.6156 20.9774 19.6156 21.4952C19.6156 22.0129 20.0353 22.4327 20.5531 22.4327H20.5647C21.0824 22.4327 21.5022 22.0129 21.5022 21.4952C21.5022 20.9774 21.0824 20.5577 20.5647 20.5577H20.5531ZM14.0687 21.4952C14.0687 20.9774 14.4885 20.5577 15.0062 20.5577H15.0178C15.5356 20.5577 15.9553 20.9774 15.9553 21.4952C15.9553 22.0129 15.5356 22.4327 15.0178 22.4327H15.0062C14.4885 22.4327 14.0687 22.0129 14.0687 21.4952ZM9.44775 20.5577C8.92999 20.5577 8.51025 20.9774 8.51025 21.4952C8.51025 22.0129 8.92999 22.4327 9.44775 22.4327H9.45933C9.9771 22.4327 10.3968 22.0129 10.3968 21.4952C10.3968 20.9774 9.9771 20.5577 9.45933 20.5577H9.44775Z"
        fill="#D8BD73"
      />
      <path
        d="M20.0546 2.5V6.61347"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.95693 2.5V6.61347"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2978 4.474H9.7137C6.04284 4.474 3.75 6.51892 3.75 10.2778V21.5898C3.75 25.4078 6.04284 27.5 9.7137 27.5H20.2863C23.9687 27.5 26.25 25.4433 26.25 21.6844V10.2778C26.2615 6.51892 23.9803 4.474 20.2978 4.474Z"
        stroke="#D8BD73"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

const CompactConvenient = ({ slice }) => (
  <div className="compact-convenient">
    <div className="compact-convenient__background" />

    <div className="compact-convenient__gradient" />

    <MaxWidth>
      <div className="compact-convenient__wrap">
        <div className="compact-convenient__map">
          <div className="compact-convenient__map__wrap">
            <img
              {...generateImageProps(slice.primary.map, {
                width: 1200,
              })}
            />
          </div>
        </div>

        <div className="compact-convenient__content">
          <Heading
            title={
              slice.primary.title ? (
                <h2>{RichText.asText(slice.primary.title)}</h2>
              ) : null
            }
          />
          {slice.primary.description ? (
            <Text>
              <PrismicRichText field={slice.primary.description} />
            </Text>
          ) : null}
          <div className="compact-convenient__content-list">
            {slice.items.map((item, index) => (
              <div
                className="compact-convenient__content-list-item"
                key={index}
              >
                <div className="compact-convenient__content-list-item-icon">
                  {item.icon ? ICONS[item.icon] : null}
                </div>

                <div className="compact-convenient__content-list-item-content">
                  <div className="compact-convenient__content-list-item-content-title">
                    {item.itemTitle ? (
                      <PrismicRichText field={item.itemTitle} />
                    ) : null}
                  </div>
                  <div className="compact-convenient__content-list-item-content-description">
                    {item.itemDescription ? (
                      <PrismicRichText field={item.itemDescription} />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {slice.primary.link.link_type != 'Any' && slice.primary.textLink ? (
            <div className="compact-convenient__content-button">
              <Button
                field={slice.primary.link}
                rightIcon="up-right-arrow"
                theme={'secondary-outline'}
              >
                {slice.primary.textLink ? (
                  <PrismicRichText field={slice.primary.textLink} />
                ) : null}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </MaxWidth>
  </div>
);

export default CompactConvenient;
