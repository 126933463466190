import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import classnames from 'classnames';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image';

const ArticlesSelection = ({ slice }) => {
  return (
    <div className="article-selection">
      <MaxWidth>
        {slice.primary.title ? (
          <div className="article-selection__title">
            <PrismicRichText field={slice.primary.title} />
          </div>
        ) : null}
        <div className={classnames('article-selection__container', {})}>
          {slice.items.map((item, index) => {
            return (
              <div className="article-selection__item" key={index}>
                <div className="article-selection__item__image">
                  <img
                    {...generateImageProps(item.image, {
                      width: 900,
                      sizes: '300px',
                    })}
                    alt={item.image.alt ?? ''}
                  />
                </div>

                <div className="article-selection__item__content">
                  {item.title ? (
                    <div className="article-selection__item__title">
                      <Link field={item.link}>
                        <PrismicRichText field={item.title} />
                      </Link>
                    </div>
                  ) : null}
                  {item.subTitle ? (
                    <div className="article-selection__item__sub-title">
                      <PrismicRichText field={item.subTitle} />
                    </div>
                  ) : null}
                  {item.description ? (
                    <div className="article-selection__item__description">
                      <PrismicRichText field={item.description} />
                    </div>
                  ) : null}

                  {item.link.link_type != 'Any' && item.textLink ? (
                    <div className="article-selection__item__button">
                      <Button
                        field={item.link}
                        theme="light"
                        rightIcon="right-arrow"
                      >
                        {item.textLink ? (
                          <PrismicRichText field={item.textLink} />
                        ) : null}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticlesSelection;
