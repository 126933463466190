import { PrismicRichText } from '@prismicio/react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

import { Button } from '../../components/Button';

import { generateImageProps, isImage } from '../../helpers/image';

import { Heading } from '../../components/Heading';
import { MaxWidth } from '../../components/MaxWidth';
import { Text } from '../../components/Text';
import { isLink } from '../../helpers/is-link';
import { RichText } from 'prismic-reactjs';

const modifiers = {
  default: null,
  videoTextWithoutBackground: 'without-background',
};

const VideoText = ({ slice }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const [mountEmbed, setMountEmbed] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(!isMobile);
  const [isFullScreen, setIsFullScreen] = useState(screenfull.isFullscreen);
  const player = useRef(null);
  const videoPlayer = useRef(null);
  const playerContainer = useRef(null);

  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      setIsFullScreen(screenfull.isFullscreen);
    });
  }

  const handlePlayPause = (e = null, toggle = null) => {
    if (player.current) {
      setIsPlaying((current) => (!toggle ? !current : toggle));

      if (!e) return;

      switch (e.detail) {
        case 2: {
          handleFullScreen();
          break;
        }
      }
    }
  };

  const handleStart = () => {
    if (player.current) {
      if (isStarted) {
        handlePlayPause();
      } else {
        player.current.seekTo(0);

        setIsMuted(false);
        setIsPlaying(true);

        setIsStarted(true);
      }
    }
  };

  const handleFullScreen = (e, toggle = null) => {
    if (player.current) {
      screenfull.toggle(playerContainer.current);

      if (!isStarted) {
        handleStart();
      }
    }
  };

  const handleReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  const videoUrl = slice.primary.videoUrl.embed_url;

  const youtubeIdMatch = videoUrl && videoUrl.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <div
      className={classnames(
        'video-text',

        modifiers[slice.variation]
          ? `video-text--${modifiers[slice.variation]}`
          : null,
        {
          'video-text--reverse': slice.primary.reverse,
        }
      )}
    >
      {slice.primary.background ? (
        <div className="video-text__background">
          {isImage(slice.primary.background) ? (
            <img
              {...generateImageProps(slice.primary.background, {
                width: 1200,
                sizes: '100vw',
              })}
            />
          ) : (
            <div className="video-text__background__carpet" />
          )}

          <div className="video-text__background__gradient" />

          <div className="video-text__background__radial-gradient" />
        </div>
      ) : null}

      <MaxWidth>
        <div className="video-text__container">
          {slice.primary.videoUrl ? (
            <div
              className={classnames('aspect-ratio', {
                'video--fullscreen': isFullScreen,
              })}
              ref={playerContainer}
            >
              <div className="aspect-ratio__wrap">
                <div className="aspect-ratio__wrap__wrap">
                  <div
                    className={classnames('video-text__video', {
                      'video--ready': isReady,
                      'video--playing': isPlaying,
                      'video--started': isStarted,
                      'video--fullscreen': isFullScreen,
                    })}
                  >
                    <div className="video__player" ref={videoPlayer}>
                      {slice.primary.mobileImage ? (
                        <div className="video__player__cover">
                          <img
                            src={slice.primary.mobileImage.url}
                            alt={slice.primary.mobileImage.alt ?? ''}
                          />
                        </div>
                      ) : null}

                      <div className="video-text__video__wrap">
                        {mountEmbed && (
                          <ReactPlayer.default
                            className="video__player__embed"
                            ref={player}
                            url={videoUrl}
                            onStart={handleReady}
                            playing={isPlaying}
                            loop
                            controls={false}
                            muted={isMuted}
                            width="100%"
                            height={`300%`}
                            config={{
                              youtube: {
                                preload: true,
                                embedOptions: {
                                  host: 'https://www.youtube-nocookie.com',
                                },
                                playerVars: {
                                  playsinline: 1,
                                  modestbranding: 1,
                                  showinfo: 0,
                                  loop: 1,
                                  playlist: youtubeId,
                                },
                              },
                            }}
                          />
                        )}

                        <div className="video__control">
                          <button
                            className="video__control__play-pause"
                            onClick={(e) => handlePlayPause(e)}
                            aria-label={isPlaying ? 'Pause' : 'Play'}
                            tabIndex={isStarted ? null : -1}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="video__fullscreen"
                      onClick={handleFullScreen}
                    >
                      <div className="video__fullscreen__img">
                        <img
                          src="/icons/fullscreen-icon.png"
                          srcSet="/icons/fullscreen-icon.svg"
                          alt=""
                          draggable={false}
                        />
                      </div>
                      <span className="visually-hidden">Plein écran</span>
                    </button>

                    <div
                      className="video__wrapper"
                      onClick={
                        isStarted && isPlaying
                          ? (event) => {
                              handlePlayPause(event);
                            }
                          : null
                      }
                    >
                      <div className="video__wrap">
                        <div className="video__play">
                          <button
                            onClick={
                              isStarted
                                ? (event) => {
                                    handlePlayPause(event);
                                  }
                                : handleStart
                            }
                            tabIndex={isStarted ? -1 : null}
                          >
                            <div className="video__play__img">
                              <img
                                src="/icons/play.png"
                                srcSet="/icons/play.svg"
                                alt=""
                                draggable={false}
                              />
                            </div>
                            <span className="visually-hidden">Play</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="video-text__content">
            <Heading
              supTitle={
                slice.primary.supTitle ? (
                  <PrismicRichText field={slice.primary.supTitle} />
                ) : null
              }
              title={
                slice.primary.title ? (
                  <h2>{RichText.asText(slice.primary.title)}</h2>
                ) : null
              }
            />

            {slice.primary.paragraph ? (
              <Text>
                <PrismicRichText field={slice.primary.paragraph} />
              </Text>
            ) : null}

            {slice.primary.linkText && isLink(slice.primary.link) && (
              <div className="video-text__content__button">
                <Button field={slice.primary.link} rightIcon="up-right-arrow">
                  {slice.primary.linkText ? (
                    <PrismicRichText field={slice.primary.linkText} />
                  ) : null}
                </Button>
              </div>
            )}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default VideoText;
