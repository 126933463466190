import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';

import { isLink } from '../../helpers/is-link.js';

const ICONS = {
  fichier: (
    <svg
      width="108"
      height="109"
      viewBox="0 0 108 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M84.6397 40.9703C82.6079 40.9703 79.9166 40.9249 76.5659 40.9249C68.3942 40.9249 61.675 34.0987 61.675 25.7739V11.1679C61.675 10.0188 60.766 9.08325 59.6387 9.08325H35.8363C24.7282 9.08325 15.75 18.2847 15.75 29.5616V78.4981C15.75 90.3291 25.156 99.9166 36.7631 99.9166H72.2082C83.2762 99.9166 92.25 90.7742 92.25 79.4882V43.014C92.25 41.8605 91.3455 40.9294 90.2137 40.934C88.3112 40.9476 86.0298 40.9703 84.6397 40.9703"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M72.3761 11.6596C71.0306 10.2472 68.6816 11.2191 68.6816 13.1766V25.1529C68.6816 30.176 72.7811 34.3089 77.7581 34.3089C80.8946 34.3453 85.2506 34.3544 88.9496 34.3453C90.8441 34.3407 91.8071 32.0563 90.4931 30.6756C85.7456 25.6934 77.2451 16.7645 72.3761 11.6596"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3832 51.7186H55.6157C57.4652 51.7186 58.9682 50.2062 58.9682 48.3396C58.9682 46.473 57.4652 44.9561 55.6157 44.9561H40.3832C38.5337 44.9561 37.0352 46.473 37.0352 48.3396C37.0352 50.2062 38.5337 51.7186 40.3832 51.7186ZM40.3832 74.4006H64.8812C66.7307 74.4006 68.2337 72.8883 68.2337 71.0216C68.2337 69.155 66.7307 67.6381 64.8812 67.6381H40.3832C38.5337 67.6381 37.0352 69.155 37.0352 71.0216C37.0352 72.8883 38.5337 74.4006 40.3832 74.4006Z"
        fill="white"
      />
    </svg>
  ),
  image: (
    <svg
      width="91"
      height="92"
      viewBox="0 0 91 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M61.9292 84.3333H29.0683C16.2172 84.3333 7.58398 75.221 7.58398 61.6564V30.3474C7.58398 16.7827 16.2172 7.66663 29.0683 7.66663H61.9331C74.7841 7.66663 83.4173 16.7827 83.4173 30.3474V61.6564C83.4173 75.221 74.7841 84.3333 61.9292 84.3333"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6385 33.2542C42.6385 38.5058 38.4108 42.78 33.2162 42.78C28.0179 42.78 23.7939 38.5058 23.7939 33.2542C23.7939 28.0025 28.0179 23.7245 33.2162 23.7245C38.4108 23.7245 42.6385 28.0025 42.6385 33.2542ZM73.5596 54.0023C74.443 54.8687 75.0762 55.8577 75.4933 56.9118C76.7559 60.1012 76.1 63.9345 74.7501 67.0932C73.1501 70.8537 70.0864 73.6942 66.2265 74.9362C64.5126 75.492 62.7154 75.7297 60.9219 75.7297H29.144C25.9817 75.7297 23.1835 74.986 20.8895 73.5907C19.4525 72.7167 19.1984 70.7042 20.2639 69.3932C22.046 67.2082 23.8053 65.0155 25.5798 62.8037C28.962 58.5717 31.2408 57.345 33.7736 58.4222C34.8011 58.8668 35.8325 59.5377 36.8941 60.243C39.7227 62.1367 43.6547 64.7357 48.8341 61.9143C52.3745 59.9572 54.4296 56.6081 56.2183 53.6931L56.2544 53.6343C56.3748 53.4393 56.4941 53.2443 56.6131 53.0499C57.2214 52.0562 57.8204 51.0776 58.4991 50.1767C59.3408 49.0612 62.4651 45.5729 66.5033 48.0569C69.0778 49.6208 71.2429 51.7368 73.5596 54.0023Z"
        fill="white"
      />
    </svg>
  ),

  dossier: (
    <svg
      width="104"
      height="105"
      viewBox="0 0 104 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M73.1667 22.3774H60.4134C57.2363 22.3986 54.2199 20.9718 52.2066 18.4953L48.0068 12.6334C46.0272 10.1352 43.0109 8.70163 39.8385 8.75125H30.8226C14.6401 8.75125 8.66801 18.3401 8.66801 34.6449V52.2697C8.64788 54.2079 95.3158 54.2055 95.3213 52.2697V47.1453C95.3984 30.8404 89.5804 22.3774 73.1667 22.3774Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.2719 28.6274C91.6583 30.264 92.7297 32.1467 93.4314 34.1789C94.8119 38.3556 95.451 42.7448 95.3193 47.1451V70.1272C95.3138 72.063 95.1721 73.9959 94.8955 75.9115C94.3687 79.2922 93.1907 82.5364 91.4278 85.4615C90.6176 86.8744 89.6337 88.1787 88.4996 89.3436C83.3654 94.101 76.5475 96.5774 69.5815 96.215H34.3653C27.3882 96.5747 20.5593 94.0994 15.4087 89.3436C14.2881 88.1765 13.3173 86.8722 12.519 85.4615C10.7666 82.5385 9.61367 79.2912 9.12838 75.9115C8.8198 73.9991 8.66518 72.0647 8.66602 70.1272V47.1451C8.66526 45.2257 8.76816 43.3077 8.97426 41.3996C9.0176 41.0648 9.0826 40.7355 9.14693 40.4096C9.25418 39.8663 9.35955 39.3324 9.35955 38.7986C9.75067 36.4959 10.4642 34.2608 11.4787 32.1602C14.484 25.677 20.6488 22.3773 30.7435 22.3773H73.1262C78.7803 21.9358 84.3923 23.6543 88.8463 27.1911C89.3593 27.6302 89.8361 28.1106 90.2719 28.6274ZM30.2034 67.9921H73.819H73.8961C74.8531 68.0341 75.7869 67.6883 76.4891 67.0318C77.1914 66.3754 77.6035 65.4629 77.6335 64.4982C77.6876 63.6503 77.4111 62.8146 76.8629 62.169C76.2322 61.3014 75.233 60.7836 74.1658 60.7714H30.2034C28.2244 60.7714 26.6201 62.3878 26.6201 64.3818C26.6201 66.3757 28.2244 67.9921 30.2034 67.9921Z"
        fill="white"
      />
    </svg>
  ),
};

export const PressResourceList = ({ slice }) => {
  return (
    <div className="press-resource__list">
      {slice.items.map((item, index) => {
        return item.ressourceType != undefined ? (
          <div
            className={classnames('press-resource__item', {
              'press-resource__item--disabled':
                !item.itemLink || !isLink(item.itemLink),
            })}
            key={index}
          >
            <div className="press-resource__item__icon">
              {item.ressourceType ? ICONS[item.ressourceType] : null}
            </div>

            <div className="press-resource__item__container">
              <div>
                {item.titleItem ? (
                  <h3 className="press-resource__item__title">
                    {item.itemLink ? (
                      <Link field={item.itemLink}>
                        {prismicH.asText(item.titleItem)}
                      </Link>
                    ) : (
                      prismicH.asText(item.titleItem)
                    )}
                  </h3>
                ) : null}

                {item.item ? (
                  <div className="press-resource__item__content">
                    <PrismicRichText field={item.item} />
                  </div>
                ) : null}
              </div>

              {item.itemLinkText && item.itemLink ? (
                <div className="press-resource__item__button">
                  <Button as="div" theme="outline-orange">
                    {item.itemLinkText ? (
                      <PrismicRichText field={item.itemLinkText} />
                    ) : null}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="press-resource__title" key={index}>
            {item.titleItem ? <PrismicRichText field={item.titleItem} /> : null}
          </div>
        );
      })}
    </div>
  );
};
