import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import { Link } from '../Link';
import { MaxWidth } from '../MaxWidth';
import { NewsletterForm } from '../NewsletterForm';

export const FooterSocial = ({ socialProfiles }) => {
  const socialLinks = socialProfiles ? socialProfiles.split('\n') : [];

  const facebook = socialLinks.find((url) => /facebook.com/.test(url));
  const X = socialLinks.find((url) => /X.com/.test(url));
  const instagram = socialLinks.find((url) => /instagram.com/.test(url));
  const tiktok = socialLinks.find((url) => /tiktok.com/.test(url));
  const youtube = socialLinks.find((url) => /youtube.com/.test(url));

  return (
    <ul className="footer__social">
      {facebook && (
        <li>
          <a href={facebook} target="_blank" rel="noopener">
            <img
              src="/icons/social/facebook.svg"
              alt="Facebook"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {instagram && (
        <li>
          <a href={instagram} target="_blank" rel="noopener">
            <img
              src="/icons/social/instagram.svg"
              alt="Instagram"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {tiktok && (
        <li>
          <a href={tiktok} target="_blank" rel="noopener">
            <img
              src="/icons/social/tiktok.svg"
              alt="TikTok"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {youtube && (
        <li>
          <a href={youtube} target="_blank" rel="noopener">
            <img
              src="/icons/social/youtube.svg"
              alt="Youtube"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {X && (
        <li>
          <a href={X} target="_blank" rel="noopener">
            <img
              src="/icons/social/X.svg"
              alt="X"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}
    </ul>
  );
};

export const Footer = ({ layout }) => {
  return (
    <footer className="footer">
      <MaxWidth>
        <div className="footer__content">
          <div className="footer__infos">
            <Link field={layout.data.logoLink} className="footer__infos__logo">
              <img
                src={layout.data.logo.url}
                alt={layout.data.logo.alt ?? ''}
              />
              <span className="visually-hidden">Open menu</span>
            </Link>

            <div className="footer__infos__address">
              <PrismicRichText field={layout.data.adresse} />
            </div>

            <FooterSocial socialProfiles={layout.data.socialProfiles} />
          </div>

          {layout.data.column.map((item, index) => (
            <div key={index} className="footer__column__item">
              <div className="footer__column__item__title">
                <PrismicRichText field={item.title} />
              </div>

              <div className="footer__column__item__links">
                <PrismicRichText field={item.links} />
              </div>

              {item.newsletter ? (
                <NewsletterForm
                  label={prismicH.asText(layout.data.newsletterTitle)}
                  placeholder={prismicH.asText(
                    layout.data.newsletterPlaceholder
                  )}
                  success={prismicH.asText(layout.data.newsletterSuccess)}
                  error={prismicH.asText(layout.data.newsletterError)}
                />
              ) : null}
            </div>
          ))}
        </div>

        <div className="footer__bottom">
          <div className="footer__bottom__copyright">
            <PrismicRichText field={layout.data.copyright} />
          </div>

          <ul className="footer__bottom__links">
            {layout.data.links.map((item) => (
              <li key={prismicH.asText(item.label)}>
                <Link field={item.link}>
                  <span>{prismicH.asText(item.label)}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </MaxWidth>
    </footer>
  );
};
