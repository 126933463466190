import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps } from '../../helpers/image';

const PartenairesListe = ({ slice }) => (
  <div className="partners-list">
    <MaxWidth>
      <div className="partners-list__container">
        <div className="partners-list__top">
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}

          {slice.primary.description ? (
            <PrismicRichText field={slice.primary.description} />
          ) : null}
        </div>

        <div
          className={classnames('partners-list__list', {
            'partners-list__list--3': slice.primary.nbPartners === '3',
            'partners-list__list--4': slice.primary.nbPartners === '4',
            'partners-list__list--5': slice.primary.nbPartners === '5',
          })}
        >
          {slice.items.map((item, index) => {
            return (
              <div className="partners-list__item" key={index}>
                {item.image ? (
                  <div
                    className={classnames('partners-list__item__logo', {
                      'partners-list__item__logo--img-middle':
                        slice.primary.imagePosition,
                    })}
                  >
                    <img
                      {...generateImageProps(item.image, {
                        width: 800,
                        sizes: '300px',
                      })}
                    />
                  </div>
                ) : null}

                <div className="partners-list__item__content">
                  <div className="partners-list__item__top">
                    {item.itemTitle ? (
                      <div className="partners-list__item__title">
                        <Link field={item.itemLink}>
                          <PrismicRichText field={item.itemTitle} />
                        </Link>
                      </div>
                    ) : null}
                    {item.itemDescription ? (
                      <PrismicRichText field={item.itemDescription} />
                    ) : null}
                  </div>
                  <div className="partners-list__item__bottom">
                    {item.itemLinkText && item.itemLink.link_type != 'Any' ? (
                      <div className="partners-list__item__link">
                        <Button
                          field={item.itemLink}
                          theme="light-blue"
                          rightIcon="right-arrow"
                        >
                          {item.itemLinkText ? (
                            <PrismicRichText field={item.itemLinkText} />
                          ) : null}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </MaxWidth>
  </div>
);

export default PartenairesListe;
