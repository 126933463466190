import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  addDays,
  isAfter,
  differenceInDays,
  isBefore,
  format,
  isToday,
  isTomorrow,
} from 'date-fns';
import { generateImageProps } from '../../helpers/image';

import { MaxWidth } from '../../components/MaxWidth';
import classNames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import { useMediaQuery } from 'react-responsive';
import smoothscroll from 'smoothscroll-polyfill';
import capitalize from 'capitalize';
import * as prismicH from '@prismicio/helpers';

const ICONS = {
  ellipse: (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="3" transform="matrix(1 0 0 -1 3 3)" fill="#DFA244" />
    </svg>
  ),
  star: (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5272 1.08508C5.77116 0.0606256 7.22884 0.0606256 7.4728 1.08508L8.18462 4.07424C8.27204 4.44134 8.55866 4.72796 8.92576 4.81538L11.9149 5.5272C12.9394 5.77116 12.9394 7.22884 11.9149 7.4728L8.92576 8.18462C8.55866 8.27204 8.27204 8.55866 8.18462 8.92576L7.4728 11.9149C7.22884 12.9394 5.77116 12.9394 5.5272 11.9149L4.81538 8.92576C4.72796 8.55866 4.44134 8.27204 4.07424 8.18462L1.08508 7.4728C0.0606256 7.22884 0.0606256 5.77116 1.08508 5.5272L4.07424 4.81538C4.44134 4.72796 4.72796 4.44134 4.81538 4.07424L5.5272 1.08508Z"
        fill="#DFA244"
      />
    </svg>
  ),
};

const EVENTS_FETCH_LIMIT = 180;
const DATE_LABELS = {
  today: "Aujourd'hui",
  tomorrow: 'Demain',
  afterTomorrow: 'Après-demain',
};
const SCROLL_QUERY = 650;
const DATE_MOBILE_WIDTH = 94;
const SCROLL_TYPES = {
  manual: 0,
  auto: 1,
};

const THEMES = {
  dark: 'sombre',
  light: 'clair',
};

const isNextDate = (nextDate, date) => {
  return (
    isAfter(nextDate, date) &&
    differenceInDays(nextDate, date) <= EVENTS_FETCH_LIMIT
  );
};

const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const components = {
  image: ({ node, ...props }) => {
    const imageProps = generateImageProps(node, {
      width: 600,
      sizes: '600px',
    });

    return (
      <p className="block-img">
        <img
          key={node.url}
          src={imageProps.src}
          srcSet={imageProps.srcSet}
          alt={node.alt}
        />
      </p>
    );
  },
};

const LastEvents = ({ slice, context }) => {
  const sliderRef = useRef(null);
  const datesRef = useRef(null);

  const currentDate = new Date();

  const lastEvents = useMemo(() => {
    const dayEvents = context.events.slice().filter((event) => {
      const eventDate = new Date(event.data.date);
      return isSameDate(eventDate, currentDate);
    });

    const nextEvents = {};

    context.events
      .slice()
      .filter((event) => {
        const eventDate = new Date(event.data.date);
        return isNextDate(eventDate, currentDate);
      })
      .sort((a, b) => {
        return new Date(b.data.date) - new Date(a.data.date);
      })
      .forEach((event) => {
        const key = format(new Date(event.data.date), 'MM/dd/yyyy');
        nextEvents[key]
          ? nextEvents[key].push(event)
          : (nextEvents[key] = [event]);
      });

    return {
      dayEvents,
      nextEvents,
      sliderStartIndex: currentDate.getDate() - 3,
    };
  }, [context.events]);

  const [currentDateState, setCurrentDateState] = useState({
    index: 3,
    date: currentDate,
    events: lastEvents.dayEvents,
    label: DATE_LABELS.today,
    nextEvents: Object.keys(lastEvents.nextEvents || {})
      .sort((a, b) => {
        return new Date(a) - new Date(b);
      })
      .filter((key) => isNextDate(new Date(key), currentDate))
      .slice(0, 2),
    type: SCROLL_TYPES.auto,
  });

  const isScrollable = useMediaQuery({
    query: `(max-width: ${SCROLL_QUERY}px)`,
  });

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const updateIndex = (index, type = SCROLL_TYPES.auto, force = false) => {
    const date = addDays(
      currentDate,
      index + lastEvents.sliderStartIndex - currentDate.getDate()
    );

    const events = isSameDate(currentDate, date)
      ? lastEvents.dayEvents
      : lastEvents.nextEvents[format(date, 'MM/dd/yyyy')];

    if (
      isAfter(date, addDays(currentDate, -1)) &&
      (index !== currentDateState.index || force)
    ) {
      setCurrentDateState((prevState) => {
        return {
          index,
          date,
          events,
          label: isToday(date)
            ? DATE_LABELS.today
            : isTomorrow(date)
            ? DATE_LABELS.tomorrow
            : differenceInDays(date, currentDate) === 2
            ? DATE_LABELS.afterTomorrow
            : '',
          nextEvents: Object.keys(lastEvents.nextEvents || {})
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .filter((key) => isNextDate(new Date(key), date))
            .slice(0, 2),
          type,
        };
      });
    }
  };

  const handleScroll = (e) => {
    if (currentDateState.type !== SCROLL_TYPES.manual) return;

    const scroll = parseInt(e.currentTarget.scrollLeft);

    const startScroll = (SCROLL_QUERY - window.innerWidth) * 0.655;

    let i =
      Math.ceil((scroll - startScroll) / DATE_MOBILE_WIDTH - 0.1) < 0
        ? 0
        : Math.ceil((scroll - startScroll) / DATE_MOBILE_WIDTH - 0.1) + 3;

    const maxScrollLeft =
      sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
    if (
      i === currentDateState.index ||
      maxScrollLeft === sliderRef.current.scrollLeft
    )
      return;

    updateIndex(i, SCROLL_TYPES.manual);
  };

  useEffect(() => {
    updateIndex(currentDateState.index, SCROLL_TYPES.auto, true);
  }, [isScrollable]);

  useEffect(() => {
    if (currentDateState.index > 2)
      if (isScrollable) {
        const startScroll = (SCROLL_QUERY - window.innerWidth) * 0.495;

        datesRef.current.style.transform = `translateX(0)`;

        if (currentDateState.type === SCROLL_TYPES.auto) {
          sliderRef.current.scrollTo({
            left:
              (currentDateState.index - 3) * DATE_MOBILE_WIDTH + startScroll,
            behavior: 'smooth',
          });

          setTimeout(() => {
            setCurrentDateState((prevState) => {
              return {
                ...prevState,
                type: SCROLL_TYPES.manual,
              };
            });
          }, 200);
        }
      } else {
        sliderRef.current.scrollLeft = 0;
        datesRef.current.style.transform = `translateX(calc(${
          currentDateState.index - 3
        } * var(--width) * -1))`;
      }
  }, [currentDateState]);

  return (
    <div
      className={classNames('last-events', {
        'last-events--light': slice.primary.theme === THEMES.light,
      })}
    >
      <MaxWidth>
        <div
          className="last-events__slider"
          ref={sliderRef}
          onScroll={(e) => {
            handleScroll(e);
          }}
        >
          <ul className="last-events__slider__list" ref={datesRef}>
            {Array(EVENTS_FETCH_LIMIT + 4)
              .fill(1)
              .map((element, index) => {
                const date = addDays(
                  currentDate,
                  index + lastEvents.sliderStartIndex - currentDate.getDate()
                );

                const events = isSameDate(date, currentDate)
                  ? lastEvents.dayEvents
                  : lastEvents.nextEvents[format(date, 'MM/dd/yyyy')];

                return (
                  <li key={index}>
                    <button
                      className={classNames('last-events__slider__list__date', {
                        'last-events__slider__list__date--current':
                          currentDateState.index === index,
                        'last-events__slider__list__date--passed': isBefore(
                          date,
                          currentDate
                        ),
                        'last-events__slider__list__date--has-event':
                          events && events.length > 0,
                      })}
                      onClick={() => updateIndex(index)}
                    >
                      <p className="last-events__slider__list__date__weekday">
                        {date.toLocaleDateString(context.layout.lang, {
                          weekday: 'long',
                        })}
                      </p>
                      <p className="last-events__slider__list__date__num">
                        {date.getDate()}
                      </p>
                      <p className="last-events__slider__list__date__month">
                        {date
                          .toLocaleDateString(context.layout.lang, {
                            month: 'long',
                          })
                          .substr(0, 3)}
                        .
                      </p>
                      <div className="last-events__slider__list__date__ellipse">
                        {ICONS.ellipse}
                      </div>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="last-events__sections">
          <div className="last-events__sections__current">
            {currentDateState.label && (
              <p className="last-events__sections__current__sup-title">
                {currentDateState.label}
              </p>
            )}
            <h3 className="last-events__sections__current__title">
              {currentDateState.date.toLocaleDateString(context.layout.lang, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </h3>
            <div className="last-events__sections__current__list">
              {currentDateState.events && currentDateState.events.length > 0 ? (
                currentDateState.events.map((event, index) => {
                  return (
                    <div
                      className="last-events__sections__current__list__event"
                      key={index}
                    >
                      <img
                        {...generateImageProps(event.data.image, {
                          width: 600,
                          sizes: '600px',
                        })}
                      />
                      <PrismicRichText
                        field={event.data.content}
                        components={components}
                      />
                    </div>
                  );
                })
              ) : (
                <p>Pas d’évènement à cette date.</p>
              )}
            </div>
          </div>
          <div className="last-events__sections__next">
            <h3 className="last-events__sections__next__title">
              {ICONS.star} Prochains évènements {ICONS.star}
            </h3>
            <div className="last-events__sections__next__sections">
              {currentDateState.nextEvents.length > 0 ? (
                currentDateState.nextEvents.map((key, index) => {
                  const dayEvents = lastEvents.nextEvents[key];
                  return (
                    <div
                      className="last-events__sections__next__section"
                      key={index}
                    >
                      <p className="last-events__sections__next__section__date">
                        {capitalize(
                          new Date(dayEvents[0].data.date).toLocaleDateString(
                            context.layout.lang,
                            {
                              weekday: 'long',
                              month: 'long',
                              day: 'numeric',
                            }
                          )
                        )}
                        :
                      </p>
                      <div className="last-events__sections__next__section__list">
                        {dayEvents.map((event, index) => {
                          return (
                            <div
                              className="last-events__sections__next__section__list__event"
                              key={index}
                            >
                              <img
                                {...generateImageProps(event.data.image, {
                                  width: 600,
                                  sizes: '600px',
                                })}
                              />

                              <PrismicRichText
                                field={event.data.content}
                                components={components}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="last-events__sections__next__section">
                  <div className="last-events__sections__next__section__list">
                    <p>Pas d’évènement à venir.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default LastEvents;
