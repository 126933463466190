import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import React from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps } from '../../helpers/image.js';
import { isLink } from '../../helpers/is-link.js';

const EchoPresse = ({ slice }) => {
  return (
    <div className="echo-press">
      <MaxWidth>
        <div className="echo-press__container">
          <div className="echo-press__top">
            {slice.primary.supTitle ? (
              <div className="echo-press__top__sup-title">
                {prismicH.asText(slice.primary.supTitle)}
              </div>
            ) : null}

            {slice.primary.title ? (
              <h2 className="echo-press__top__title">
                {prismicH.asText(slice.primary.title)}
              </h2>
            ) : null}
          </div>

          <div className="echo-press__bottom">
            <ul className="echo-press__list">
              {slice.items
                .filter((item) => item.priority === false)
                .map((item, index) => {
                  return (
                    <li className="echo-press__list__item" key={index}>
                      {item.icon.url != undefined ? (
                        <div className="echo-press__list__item__icon">
                          <img
                            {...generateImageProps(item.icon, {
                              width: 200,
                              sizes: '60px',
                            })}
                          />
                        </div>
                      ) : null}
                      <div className="echo-press__list__item__container">
                        {item.itemSupTitle ? (
                          <div className="echo-press__list__item__sup-title">
                            <PrismicRichText field={item.itemSupTitle} />
                          </div>
                        ) : null}

                        {item.titleContent ? (
                          <h3 className="echo-press__list__item__title">
                            {item.link ? (
                              <Link field={item.link}>
                                {prismicH.asText(item.titleContent)}
                              </Link>
                            ) : (
                              prismicH.asText(item.titleContent)
                            )}
                          </h3>
                        ) : null}

                        {item.content ? (
                          <div className="echo-press__list__item__content">
                            <PrismicRichText field={item.content} />
                          </div>
                        ) : null}
                      </div>

                      {item.linkText && item.link && isLink(item.link) ? (
                        <div className="echo-press__list__item__button">
                          <Button
                            as="div"
                            theme="light"
                            rightIcon="right-arrow"
                          >
                            {item.linkText ? (
                              <PrismicRichText field={item.linkText} />
                            ) : null}
                          </Button>
                        </div>
                      ) : null}
                    </li>
                  );
                })}
            </ul>

            <ul className="echo-press__list__priority">
              {slice.items
                .filter((item) => item.priority === true)
                .map((item, index) => {
                  return (
                    <li
                      className="echo-press__list__priority__item"
                      key={index}
                    >
                      {item.image ? (
                        <div className="echo-press__list__priority__item__image">
                          <img
                            {...generateImageProps(item.image, {
                              width: 800,
                              sizes: '400px',
                            })}
                          />
                        </div>
                      ) : null}

                      {item.itemSupTitle ? (
                        <div className="echo-press__list__priority__item__sup-title">
                          {item.link ? (
                            <Link field={item.link}>
                              {prismicH.asText(item.itemSupTitle)}
                            </Link>
                          ) : (
                            prismicH.asText(item.itemSupTitle)
                          )}
                        </div>
                      ) : null}

                      {item.content ? (
                        <div className="echo-press__list__priority__item__content">
                          <PrismicRichText field={item.content} />
                        </div>
                      ) : null}

                      {item.linkText && item.link && isLink(item.link) ? (
                        <div className="echo-press__list__priority__item__button">
                          <Button
                            as="div"
                            theme="light"
                            rightIcon="right-arrow"
                          >
                            {prismicH.asText(item.linkText)}
                          </Button>
                        </div>
                      ) : null}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default EchoPresse;
