import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React from 'react';
import * as prismicH from '@prismicio/helpers';

import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { MaxWidth } from '../../components/MaxWidth';
import { Text } from '../../components/Text';

import { isImage, generateImageProps } from '../../helpers/image.js';
import { RichText } from 'prismic-reactjs';

const Banner = ({ slice, index }) => {
  return (
    <div
      className={classnames('banner', {
        'banner--carpet': !isImage(slice.primary.background),
        'banner--first': index === 0,
      })}
    >
      <div className={classnames('banner__background')}>
        {isImage(slice.primary.background) ? (
          <img
            {...generateImageProps(slice.primary.background, {
              width: 3000,
            })}
          />
        ) : null}

        <div className="banner__background__gradient" />
      </div>

      <MaxWidth>
        <div className="banner__container">
          <Heading
            ruler={false}
            title={
              slice.primary.title ? (
                <h2>{RichText.asText(slice.primary.title)}</h2>
              ) : null
            }
          />

          {prismicH.asText(slice.primary.description) ? (
            <Text>
              <PrismicRichText field={slice.primary.description} />
            </Text>
          ) : null}

          <div className="banner__container__button">
            {prismicH.asText(slice.primary.buttonText) ? (
              <Button
                field={slice.primary.buttonLink}
                leftIcon="ticket"
                rightIcon="up-right-arrow"
              >
                {slice.primary.buttonText ? (
                  <PrismicRichText field={slice.primary.buttonText} />
                ) : null}
              </Button>
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Banner;
