import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';
import { Text } from '../../components/Text';

import smoothscroll from 'smoothscroll-polyfill';
import { useMediaQuery } from 'react-responsive';

const PRATIQUE_INDEX_ATTRIBUTE_NAME = 'pratique-index';

const ICONS = {
  handicape: (
    <svg
      width="85"
      height="84"
      viewBox="0 0 85 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M59.2621 77.8107H28.5677C16.564 77.8107 8.5 69.6193 8.5 57.4254V29.2804C8.5 17.0866 16.564 8.89172 28.5677 8.89172H59.2656C71.2693 8.89172 79.3333 17.0866 79.3333 29.2804V57.4254C79.3333 69.6193 71.2693 77.8107 59.2621 77.8107"
        fill="white"
      />
      <path
        d="M41.1914 42.6007L41.0371 40.5645L50.5985 40.5682L50.8031 40.5683V40.3637V36.7436V36.5391L50.5987 36.539L40.6363 36.5352L40.2099 29.1701C42.2277 28.8914 43.7726 27.1661 43.7726 25.1505C43.7726 22.91 41.8966 21.0927 39.6077 21.0927C37.3188 21.0927 35.4429 22.91 35.4429 25.1505C35.4429 25.8196 35.6299 26.4997 35.9417 27.0762L37.3496 46.3513L37.3634 46.5409L37.5535 46.541L51.9532 46.5448L57.8605 60.0118L57.9388 60.1901L58.1208 60.1207L65.947 57.1344L66.1465 57.0583L66.0619 56.8622L64.85 54.0544L64.7744 53.8793L64.5944 53.9425L60.3924 55.4183L54.7016 42.635L54.6472 42.5128L54.5134 42.5136L41.1914 42.6007Z"
        fill="white"
        stroke="white"
        strokeWidth="0.409113"
      />
      <path
        d="M51.2234 56.7895L51.2717 56.6968L51.2247 56.6034L48.6402 51.4572L48.336 50.8515L48.2543 51.5243C47.6465 56.5316 43.1847 60.444 37.9464 60.444C32.2192 60.444 27.5571 55.9 27.5571 50.341C27.5571 46.9094 29.3823 43.6335 32.3426 41.8185L32.4494 41.753L32.4396 41.628L32.1091 37.4314L32.0864 37.1434L31.8223 37.2605C26.6439 39.5555 23.1704 44.7332 23.1704 50.3123C23.1704 58.2565 29.8276 64.7257 37.9786 64.7257C43.5298 64.7257 48.7346 61.5764 51.2234 56.7895Z"
        fill="white"
        stroke="white"
        strokeWidth="0.409113"
      />
    </svg>
  ),
  sac: (
    <svg
      width="88"
      height="87"
      viewBox="0 0 88 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.682 58.9065L73.8627 36.8066C72.1452 28.9207 67.2843 25.6755 62.6503 25.6755H25.4163C20.7175 25.6755 15.6946 28.6936 14.2363 36.8066L11.3847 58.9065C9.05145 75.1326 17.6389 79.1891 28.8513 79.1891H59.2477C70.4277 79.1891 78.7559 73.3152 76.682 58.9065ZM33.3574 44.0436C31.5676 44.0436 30.1168 42.5907 30.1168 40.7984C30.1168 39.0061 31.5676 37.5532 33.3574 37.5532C35.1471 37.5532 36.5979 39.0061 36.5979 40.7984C36.5979 42.5907 35.1471 44.0436 33.3574 44.0436ZM51.3391 40.7984C51.3391 42.5907 52.7899 44.0436 54.5796 44.0436C56.3693 44.0436 57.8202 42.5907 57.8202 40.7984C57.8202 39.0061 56.3693 37.5532 54.5796 37.5532C52.7899 37.5532 51.3391 39.0061 51.3391 40.7984Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M62.2359 24.8704C62.2474 25.1471 62.1928 25.4226 62.0762 25.6755H56.8068C56.7048 25.4184 56.6507 25.1458 56.6471 24.8704C56.6471 18.0296 50.9279 12.484 43.8728 12.484C36.8177 12.484 31.0985 18.0296 31.0985 24.8704C31.1468 25.1367 31.1468 25.4092 31.0985 25.6755H25.7013C25.653 25.4092 25.653 25.1367 25.7013 24.8704C26.1114 15.3506 34.19 7.83765 44.0165 7.83765C53.843 7.83765 61.9216 15.3506 62.3317 24.8704H62.2359Z"
        fill="white"
      />
    </svg>
  ),
  plus: (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M66.9173 35.8102C66.9173 52.1582 53.2998 65.4048 36.5006 65.4048C19.7015 65.4048 6.08398 52.1582 6.08398 35.8102C6.08398 19.468 19.7015 6.21558 36.5006 6.21558C53.2998 6.21558 66.9173 19.468 66.9173 35.8102"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8773 32.2694C20.8668 32.2694 19.2334 33.8586 19.2334 35.8089C19.2334 37.7622 20.8668 39.3544 22.8773 39.3544C24.8879 39.3544 26.5212 37.7622 26.5212 35.8089C26.5212 33.8586 24.8879 32.2694 22.8773 32.2694ZM36.5007 32.2694C34.4901 32.2694 32.8567 33.8586 32.8567 35.8089C32.8567 37.7622 34.4901 39.3544 36.5007 39.3544C38.5112 39.3544 40.1446 37.7622 40.1446 35.8089C40.1446 33.8586 38.5112 32.2694 36.5007 32.2694ZM46.4801 35.8089C46.4801 33.8586 48.1135 32.2694 50.124 32.2694C52.1345 32.2694 53.7679 33.8586 53.7679 35.8089C53.7679 37.7622 52.1345 39.3544 50.124 39.3544C48.1135 39.3544 46.4801 37.7622 46.4801 35.8089Z"
        fill="white"
      />
    </svg>
  ),

  audio: (
    <svg
      width="66"
      height="65"
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.731 17.2598C36.5926 15.9585 36.4484 14.6084 36.0938 13.2611C35.1277 10.0381 32.4517 8.02725 29.5825 8.02725C27.9821 8.0215 25.9578 8.98095 24.8101 9.95189L15.2969 17.7051H10.3169C6.65176 17.7051 3.70468 20.4541 3.14813 24.4212C2.67522 28.2303 2.55987 35.4204 3.14813 39.6116C3.65854 43.8027 6.47298 46.5116 10.3169 46.5116H15.2969L24.9946 54.3796C25.9895 55.2213 27.7456 56.1865 29.3605 56.1865C29.4643 56.1894 29.5565 56.1894 29.6488 56.1894C32.5728 56.1894 35.1479 54.1039 36.1139 50.8894C36.4802 49.5221 36.607 48.2409 36.731 47.0028L36.8608 45.7762C37.3568 41.7976 37.3568 22.3932 36.8608 18.4433L36.731 17.2598Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M47.8683 17.3775C47.0753 16.2399 45.5038 15.9556 44.3532 16.7455C43.2141 17.5412 42.9315 19.1097 43.7246 20.2444C45.9305 23.4071 47.1446 27.6183 47.1446 32.1082C47.1446 36.5952 45.9305 40.8094 43.7246 43.9721C42.9315 45.1068 43.2141 46.6752 44.3561 47.4709C44.7828 47.7639 45.2817 47.9191 45.795 47.9191C46.6226 47.9191 47.3954 47.514 47.8683 46.839C50.6539 42.846 52.1909 37.615 52.1909 32.1082C52.1909 26.6014 50.6539 21.3704 47.8683 17.3775"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M56.5605 9.25042C55.7732 8.11574 54.1959 7.8256 53.0482 8.61844C51.9092 9.41416 51.6266 10.9826 52.4167 12.1173C56.1481 17.4661 58.2042 24.5672 58.2042 32.1078C58.2042 39.6512 56.1481 46.7523 52.4167 52.1011C51.6266 53.2387 51.9092 54.8042 53.0511 55.5999C53.4779 55.8929 53.9738 56.0481 54.4871 56.0481C55.3147 56.0481 56.0904 55.643 56.5605 54.968C60.8744 48.789 63.2505 40.671 63.2505 32.1078C63.2505 23.5503 60.8744 15.4294 56.5605 9.25042"
        fill="white"
      />
    </svg>
  ),
};

const ServicesPratiques = ({ slice, menuRef = useRef(null) }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const [pratiqueIndex, setPratiqueIndex] = useState(0);

  const currentPratiqueIndex = pratiqueIndex;

  const updatePratiqueIndex = (index) => {
    setPratiqueIndex(index);
    if (isMobile)
      window.scrollTo({
        top: menuRef.current.offsetTop + 100,
        behavior: 'smooth',
      });
  };

  return (
    <div className="pratical-services" ref={menuRef}>
      <MaxWidth>
        <div className="pratical-services__container">
          <div className="pratical-services__menu">
            {slice.items.map((item, index) => (
              <Link
                className="pratical-services__item__link"
                onClick={() => updatePratiqueIndex(index)}
                {...{
                  [PRATIQUE_INDEX_ATTRIBUTE_NAME]: index,
                }}
                key={index}
              >
                <div
                  className={classnames('pratical-services__item', {
                    'pratical-services__item--current':
                      index === currentPratiqueIndex,
                  })}
                  key={index}
                >
                  <div className="pratical-services__icon">
                    {item.icon ? ICONS[item.icon] : null}
                  </div>

                  {item.itemTitle ? (
                    <PrismicRichText field={item.itemTitle} />
                  ) : null}
                </div>
              </Link>
            ))}
          </div>

          <div className="pratical-services__content__container">
            <div className="pratical-services__content__text">
              <div className="pratical-services__content__scroll">
                <div className="pratical-services__contentr__wrap">
                  {slice.items.map((item, index) => (
                    <div
                      className={classnames(
                        'pratical-services__content__text__item',
                        {
                          'pratical-services__content__text__item--current':
                            index === currentPratiqueIndex,
                        }
                      )}
                      key={index}
                    >
                      <div className="pratical-services__content__text__item__title">
                        {item.itemTitle ? (
                          <PrismicRichText field={item.itemTitle} />
                        ) : null}
                      </div>

                      {item.itemContent ? (
                        <Text>
                          <PrismicRichText field={item.itemContent} />
                        </Text>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ServicesPratiques;
