import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React, { useState } from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';
import { PlusIcon, SubtractIcon } from '../../components/Icons';
import { Ruler } from '../../components/Ruler';
import { Text } from '../../components/Text';

import { isLink } from '../../helpers/is-link';
import { RichText } from 'prismic-reactjs';

const FAQ_INDEX_ATTRIBUTE_NAME = 'faq-index';

const Faq = ({ slice }) => {
  const [faqIndex, setFaqIndex] = useState(null);

  const currentFaqIndex = faqIndex;

  return (
    <div className="faq">
      <MaxWidth>
        <div className="faq__container">
          <div className="faq__header">
            <Ruler />
            {slice.primary.title ? (
              <div className="faq__header__title">
                <PrismicRichText field={slice.primary.title} />
              </div>
            ) : null}

            {slice.primary.description ? (
              <div className="faq__header__description">
                <PrismicRichText field={slice.primary.description} />
              </div>
            ) : null}

            {isLink(slice.primary.cta_link) && slice.primary.cta_label ? (
              <div className="faq__header__cta">
                <Button
                  field={slice.primary.cta_link}
                  horizontalPadding="large"
                  theme="primary"
                >
                  {prismicH.asText(slice.primary.cta_label)}
                </Button>
              </div>
            ) : null}
          </div>

          <div className="faq__content">
            {slice.items.map((item, index) => (
              <div className="faq__content__item" key={index}>
                <div className="faq__content__item__header">
                  <div className="faq__content__item__header__title">
                    <Link
                      className="faq__content__item__header__title__link"
                      onClick={
                        currentFaqIndex === index
                          ? () => setFaqIndex(null)
                          : () => setFaqIndex(index)
                      }
                      {...{
                        [FAQ_INDEX_ATTRIBUTE_NAME]: index,
                      }}
                    >
                      <PrismicRichText field={item.title} />
                    </Link>
                  </div>
                  <div className="faq__content__item__header__icon">
                    {index === currentFaqIndex ? (
                      <SubtractIcon />
                    ) : (
                      <PlusIcon />
                    )}
                  </div>
                </div>
                <div
                  className={classnames('faq__content__item__content', {
                    'faq__content__item__content--current':
                      index === currentFaqIndex,
                  })}
                >
                  <Text>
                    <PrismicRichText field={item.content} />
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Faq;
