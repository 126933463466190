import React, { useEffect, useRef } from 'react';

import { generateImageProps } from '../../helpers/image';

const ANIMATION_DURATION = 40000;

export const ChronologicalAnimation = ({ items = [] }) => {
  const activeRef = useRef(true);

  const imagesRef = useRef({});
  const backgroundRef = useRef(null);
  const parentRef = useRef(null);

  useEffect(() => {
    activeRef.current = true;

    return () => {
      activeRef.current = false;
    };
  }, []);

  useEffect(() => {
    const getElementPositions = (element) => {
      const fields = {
        left: 0,
        top: 0,
      };

      const container = {
        width: getComputedStyle(element.parentElement).width.replaceAll(
          'px',
          ''
        ),
        height: getComputedStyle(element.parentElement).height.replaceAll(
          'px',
          ''
        ),
      };

      Object.entries(fields).forEach(([name], index) => {
        const positionPx = parseInt(
          getComputedStyle(element)[name].replaceAll('px', '')
        );

        fields[name] = Math.round(
          (positionPx / Object.values(container)[index]) * 100
        );
      });

      return fields;
    };

    const elementsPositions = {};

    const startAnimation = () => {
      if (activeRef.current) {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;

        // BACKGROUND PART
        backgroundRef.current.style.opacity = '1';
        backgroundRef.current.style.transform = 'scale(1)';
        backgroundRef.current.style.transition = `transform ${
          ANIMATION_DURATION * 1.6
        }ms, opacity ${ANIMATION_DURATION * 0.2}ms ease`;

        const newBackground = backgroundRef.current.cloneNode();
        parentRef.current.insertBefore(newBackground, backgroundRef.current);

        backgroundRef.current.style.display = `block`;

        setTimeout(() => {
          if (activeRef.current) {
            backgroundRef.current.style.transform = `scale(2)`;
          }
        }, 20);

        setTimeout(() => {
          if (activeRef.current) {
            newBackground.style.transform = 'scale(1.5)';
            backgroundRef.current.style.opacity = '0';

            setTimeout(() => {
              if (activeRef.current) {
                backgroundRef.current.remove();
                backgroundRef.current = newBackground;
              }
            }, ANIMATION_DURATION * 0.34);
          }
        }, ANIMATION_DURATION * 0.65);

        // IMAGES PART

        Object.values(imagesRef.current).forEach((element, i) => {
          if (!elementsPositions[i]) {
            elementsPositions[i] = getElementPositions(element);
          }

          const positions = elementsPositions[i];

          const leftModifier =
            positions.left < 30 ? -20 : positions.left > 50 ? 50 : 0;

          setTimeout(() => {
            if (activeRef.current) {
              element.style.transition = `left ${ANIMATION_DURATION}ms ease-out, top ${ANIMATION_DURATION}ms ease-out, transform ${ANIMATION_DURATION}ms ease-out, opacity ${
                ANIMATION_DURATION * 0.15
              }ms ease-in-out`;

              setTimeout(() => {
                if (activeRef.current) {
                  element.style.opacity = `${i % 2 ? 1 : 0.4}`;
                  element.style.top = `${positions.top + 30}%`;
                  element.style.left = `${positions.left + leftModifier}%`;
                  element.style.transform = `scale(${isMobile ? 2 : 3.5})`;
                }
              }, 20);

              setTimeout(() => {
                if (activeRef.current) {
                  element.style.opacity = '0';

                  setTimeout(() => {
                    if (activeRef.current) {
                      element.style.transition = 'none';

                      setTimeout(() => {
                        if (activeRef.current) {
                          element.style.top = `${positions.top}%`;
                          element.style.left = `${positions.left}%`;
                          element.style.transform = `scale(0.2)`;
                        }
                      }, 20);
                    }
                  }, ANIMATION_DURATION * (0.15 + 0.01));
                }
              }, ANIMATION_DURATION * 0.266);
            }
          }, ANIMATION_DURATION * 0.175 * i);
        });
      }
    };

    startAnimation();

    setInterval(() => {
      startAnimation();
    }, ANIMATION_DURATION);
  }, []);

  return (
    <div className="journey__timeline-background__left" ref={parentRef}>
      <div
        className="journey__timeline-background__left__background"
        ref={backgroundRef}
      />

      {items.map((item, index) => {
        return (
          <img
            key={index}
            className="journey__timeline-background__left__image"
            id={`journey__timeline-background__left__image__${index}`}
            ref={(element) => {
              imagesRef.current[index] = element;
            }}
            {...generateImageProps(item.picture, {
              width: 1000,
              sizes: '1200px',
              lazy: true,
            })}
          />
        );
      })}
    </div>
  );
};
