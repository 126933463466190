import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import { Button } from '../../components/Button';
import { FooterSocial } from '../../components/Footer';
import * as prismicH from '@prismicio/helpers';
import { RichText } from 'prismic-reactjs';
import { generateImageProps } from '../../helpers/image';

const components = {
  image: ({ node, ...props }) => {
    const imageProps = generateImageProps(node, {
      width: 1200,
      sizes: '600px',
    });

    return (
      <p className="block-img">
        <img
          key={node.url}
          src={imageProps.src}
          srcSet={imageProps.srcSet}
          alt={node.alt}
        />
      </p>
    );
  },
};

const ArticleContent = ({ slice, context }) => {
  const article =
    context.document && context.document.type === 'article'
      ? context.document
      : null;

  if (!article) {
    return null;
  }
  const date = new Date(article.data.publishDate);

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: prismicH.asText(article.data.title),
    image: [article.data.image.url],
    datePublished: article.first_publication_date,
    dateModified: article.last_publication_date,
    author: prismicH.asText(article.data.author)
      ? {
          '@type': 'Person',
          name: prismicH.asText(article.data.author),
          url: '',
        }
      : [],
  };

  return (
    <div className="page-article">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />

      <MaxWidth>
        <div className="page-article__mobile-gradient"></div>
        <div className="page-article__heading">
          <img src={article.data.image.url} alt={article.data.image.alt} />
        </div>
        <div className="page-article__content">
          <div className="article__content__container">
            <div className="article__sup-title">
              {prismicH.asText(article.data.supTitle)}
            </div>

            <h1 className="article__title">
              {prismicH.asText(article.data.title)}
            </h1>

            <div className="article__sub-title">
              <PrismicRichText field={article.data.subTitle} />
            </div>

            <div className="article__date">
              <p>
                {RichText.asText(article.data.publishDateText).replace(
                  '%DATE%',
                  date.toLocaleDateString(context.layout.lang, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                )}
              </p>
            </div>

            <div className="article__content">
              <PrismicRichText
                field={article.data.content}
                components={components}
              />
            </div>
          </div>

          <div className="article__sidebar">
            <div className="article__sidebar__title">
              <PrismicRichText field={slice.primary.sidebarTitle} />
            </div>

            <div className="article__sidebar__description">
              <PrismicRichText field={slice.primary.sidebarDescription} />
            </div>

            <div className="article__sidebar__button">
              <Button
                field={slice.primary.ButtonLinkSidebar}
                height="small"
                rightIcon="up-right-arrow"
                theme="primary-white"
              >
                <PrismicRichText field={slice.primary.buttonTextSidebar} />
              </Button>
            </div>

            <div className="article__sidebar__footer">
              <div className="article__sidebar__footer__text">
                <PrismicRichText field={slice.primary.socialsText} />
              </div>

              <div className="article__sidebar__footer__buttons">
                <FooterSocial
                  socialProfiles={context.layout.data.socialProfiles}
                />
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticleContent;
